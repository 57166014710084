import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/fructidor/fructidor-ui/releases/20250124074430/app/[lng]/(home)/search/HeaderSearchBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/fructidor/fructidor-ui/releases/20250124074430/app/[lng]/(RouteBlockers)/AuthorizationWrapper.tsx");
;
import(/* webpackMode: "eager" */ "/opt/fructidor/fructidor-ui/releases/20250124074430/app/[lng]/(RouteBlockers)/FeatureFlagStoreInitaitor.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PanelOne","PanelTwo","PanelThree"] */ "/opt/fructidor/fructidor-ui/releases/20250124074430/app/[lng]/components/AdsFructidor/HomePage/ContentAdsPanel.tsx");
;
import(/* webpackMode: "eager" */ "/opt/fructidor/fructidor-ui/releases/20250124074430/app/[lng]/components/AdsFructidor/HomePage/LeftAdsPanel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/fructidor/fructidor-ui/releases/20250124074430/app/[lng]/components/BuyersAlert.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/fructidor/fructidor-ui/releases/20250124074430/app/[lng]/components/Categories.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/fructidor/fructidor-ui/releases/20250124074430/app/[lng]/components/CompaniesOutlet/CompaniesOutlet.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/fructidor/fructidor-ui/releases/20250124074430/app/[lng]/components/EventsOutlet/EventsOutlet.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/fructidor/fructidor-ui/releases/20250124074430/app/[lng]/components/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LanguageDropDown"] */ "/opt/fructidor/fructidor-ui/releases/20250124074430/app/[lng]/components/Language/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/fructidor/fructidor-ui/releases/20250124074430/app/[lng]/components/NavBar/LogoSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/fructidor/fructidor-ui/releases/20250124074430/app/[lng]/components/NavBar/NavItemsSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/fructidor/fructidor-ui/releases/20250124074430/app/[lng]/components/NavBar/UserProfile.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/fructidor/fructidor-ui/releases/20250124074430/app/[lng]/components/NewsLetter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/fructidor/fructidor-ui/releases/20250124074430/app/[lng]/components/NewsOutlet/NewsOutlet.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/fructidor/fructidor-ui/releases/20250124074430/app/[lng]/components/SalesOffers.tsx");
;
import(/* webpackMode: "eager" */ "/opt/fructidor/fructidor-ui/releases/20250124074430/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/opt/fructidor/fructidor-ui/releases/20250124074430/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
