import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface Product {
  id: number
  name: string
  checked: boolean
}
export interface ProductState {
  cultivationType: string[]
  order: string
  searchTerm: string
  sector: string
  productFruitVegetableActivity: string[]
  productFVAvailability: string
  productFilterFvSelectedProducts: Product[]
  productFilterPpSelectedProducts: Product[]
  productFilterTlSelectedProducts: Product[]
  productSelectedSortOption: string
  clearFilter: boolean
  isRouteUpdating: boolean
}

export const productInitalState = {
  sector: 'Fruit & Vegetable',
  cultivationType: [],
  order: 'desc',
  searchTerm: '',
  productFruitVegetableActivity: [],
  productFVAvailability: '',
  productFilterFvSelectedProducts: [],
  productFilterPpSelectedProducts: [],
  productFilterTlSelectedProducts: [],
  productSelectedSortOption: 'Publish Date',
  clearFilter: false,
  isRouteUpdating: false,
}
const initialState: ProductState = {
  ...productInitalState,
}

export const productFvStaticSupplier = [
  'Grower',
  'Greenhouses Grower',
  'Cooperative',
  'Exporter',
  'Shipper',
  'Broker',
  'Packer',
  'Ripening room',
  'SK Logistics',
]
export const productFvStaticBuyer = [
  'Buying central',
  'Importer',
  'Wholesaler/Distributor',
  'Hypermarket',
  'Catering',
  'Processor',
  'Supermarket',
  'Secondary wholesaler',
]

export const productFilterSlice = createSlice({
  name: 'productFilter',
  initialState,
  reducers: {
    addOrRemoveFruitVegetableActivity: (
      state,
      action: PayloadAction<string>
    ) => {
      if (state.productFruitVegetableActivity.includes(action.payload)) {
        state.productFruitVegetableActivity =
          state.productFruitVegetableActivity.filter(
            (activity) => activity !== action.payload
          )
      } else {
        state.productFruitVegetableActivity = [
          ...state.productFruitVegetableActivity,
          action.payload,
        ]
      }
    },
    setSelectAllProductBuyers: (state) => {
      const activitySet = new Set(state.productFruitVegetableActivity)
      if (productFvStaticBuyer.every((buyer) => activitySet.has(buyer))) {
        const newFruitVegetableActivity =
          state.productFruitVegetableActivity.filter(
            (activity) => !productFvStaticBuyer.includes(activity)
          )
        state.productFruitVegetableActivity = newFruitVegetableActivity
      } else {
        const buyerList: string[] = productFvStaticBuyer.filter(
          (buyer) => !state.productFruitVegetableActivity.includes(buyer)
        )

        state.productFruitVegetableActivity = [
          ...state.productFruitVegetableActivity,
          ...buyerList,
        ]
      }
    },
    setSelectAllProductSuppliers: (state) => {
      const activitySet = new Set(state.productFruitVegetableActivity)
      if (
        productFvStaticSupplier.every((supplier) => activitySet.has(supplier))
      ) {
        const newFruitVegetableActivity =
          state.productFruitVegetableActivity.filter(
            (activity) => !productFvStaticSupplier.includes(activity)
          )
        state.productFruitVegetableActivity = newFruitVegetableActivity
      } else {
        const supplierList: string[] = productFvStaticSupplier.filter(
          (supplier) => !state.productFruitVegetableActivity.includes(supplier)
        )

        state.productFruitVegetableActivity = [
          ...state.productFruitVegetableActivity,
          ...supplierList,
        ]
      }
    },

    setAddOrRemoveAvailability: (state, action: PayloadAction<string>) => {
      if (state.productFVAvailability == action.payload) {
        state.productFVAvailability = ''
      } else {
        state.productFVAvailability = action.payload
      }
    },
    addAllCultivationType: (state) => {
      state.cultivationType = ['Conventional', 'Organic']
    },
    removeAllCultivationType: (state) => {
      state.cultivationType = []
    },
    setCultivationType: (state, action: PayloadAction<string>) => {
      if (state.cultivationType.includes(action.payload)) {
        state.cultivationType = state.cultivationType.filter(
          (cType) => cType !== action.payload
        )
      } else {
        state.cultivationType = [...state.cultivationType, action.payload]
      }
    },
    setFvProductSelectAllProducts: (
      state,
      action: PayloadAction<Product[]>
    ) => {
      if (
        state.productFilterFvSelectedProducts.length !== action.payload.length
      ) {
        state.productFilterFvSelectedProducts = action.payload
      } else if (
        state.productFilterFvSelectedProducts.length == action.payload.length
      ) {
        state.productFilterFvSelectedProducts = []
      }
    },

    addOrRemoveFvProductFilterSelectedProduct: (
      state,
      action: PayloadAction<Product>
    ) => {
      if (
        state.productFilterFvSelectedProducts.some(
          (p) => p.id === action.payload.id
        )
      ) {
        state.productFilterFvSelectedProducts =
          state.productFilterFvSelectedProducts.filter(
            (p) => p.id !== action.payload.id
          )
      } else {
        state.productFilterFvSelectedProducts = [
          ...state.productFilterFvSelectedProducts,
          action.payload,
        ]
      }
    },
    setPpProductSelectAllProducts: (
      state,
      action: PayloadAction<Product[]>
    ) => {
      if (
        state.productFilterPpSelectedProducts.length !== action.payload.length
      ) {
        state.productFilterPpSelectedProducts = action.payload
      } else if (
        state.productFilterPpSelectedProducts.length == action.payload.length
      ) {
        state.productFilterPpSelectedProducts = []
      }
    },

    addOrRemovePpProductFilterSelectedProduct: (
      state,
      action: PayloadAction<Product>
    ) => {
      if (
        state.productFilterPpSelectedProducts.some(
          (p) => p.id === action.payload.id
        )
      ) {
        state.productFilterPpSelectedProducts =
          state.productFilterPpSelectedProducts.filter(
            (p) => p.id !== action.payload.id
          )
      } else {
        state.productFilterPpSelectedProducts = [
          ...state.productFilterPpSelectedProducts,
          action.payload,
        ]
      }
    },
    setTlProductSelectAllProducts: (
      state,
      action: PayloadAction<Product[]>
    ) => {
      if (
        state.productFilterTlSelectedProducts.length !== action.payload.length
      ) {
        state.productFilterTlSelectedProducts = action.payload
      } else if (
        state.productFilterTlSelectedProducts.length == action.payload.length
      ) {
        state.productFilterTlSelectedProducts = []
      }
    },

    addOrRemoveTlProductFilterSelectedProduct: (
      state,
      action: PayloadAction<Product>
    ) => {
      if (
        state.productFilterTlSelectedProducts.some(
          (p) => p.id === action.payload.id
        )
      ) {
        state.productFilterTlSelectedProducts =
          state.productFilterTlSelectedProducts.filter(
            (p) => p.id !== action.payload.id
          )
      } else {
        state.productFilterTlSelectedProducts = [
          ...state.productFilterTlSelectedProducts,
          action.payload,
        ]
      }
    },
    setProductOrder: (state, action) => {
      state.order = action.payload
    },

    setProductSearchTerm: (state, action) => {
      state.searchTerm = action.payload
    },
    setProductSector: (state, action) => {
      state.sector = action.payload
    },
    setProductSelectedSortOption: (state, action) => {
      state.productSelectedSortOption = action.payload
    },
    setProductFilter: (state, action: PayloadAction<Partial<ProductState>>) => {
      return {
        ...state,
        ...action.payload,
      }
    },

    setProductFiltersToInitialState: (state) => {
      return {
        ...initialState,
        clearFilter: !state.clearFilter,
      }
    },
    setProductFvActivity: (state, action) => {
      state.productFruitVegetableActivity = action.payload
    },
  },
})

export const {
  setCultivationType,
  setProductOrder,
  setProductSearchTerm,
  setProductSector,
  addAllCultivationType,
  removeAllCultivationType,
  addOrRemoveFruitVegetableActivity,
  setAddOrRemoveAvailability,
  setFvProductSelectAllProducts,
  addOrRemoveFvProductFilterSelectedProduct,
  setPpProductSelectAllProducts,
  addOrRemovePpProductFilterSelectedProduct,
  setTlProductSelectAllProducts,
  addOrRemoveTlProductFilterSelectedProduct,
  setProductFiltersToInitialState,
  setSelectAllProductSuppliers,
  setSelectAllProductBuyers,
  setProductSelectedSortOption,
  setProductFilter,
  setProductFvActivity,
} = productFilterSlice.actions

export default productFilterSlice.reducer
