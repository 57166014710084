'use client'
import useConsentCheck from '@/app/hooks/useConsentCheck'
import { useIntersectionObserver } from '@/app/hooks/useIntersectionObserver'
import { useTranslation } from '@/app/i18n/client'
import { Separator } from '@/components/ui/separator'
import Image from 'next/image'
import Link from 'next/link'
import { useParams } from 'next/navigation'
import { MutableRefObject, useEffect, useRef } from 'react'
import NewsResultCardContent from '../../(home)/search/news-tab/(components)/NewsResultCardContent'
import { gtmNewsCardViewTracker } from '../GTMTrackers/GTMNewsTracker'
import { NewsDetail } from '@/app/types/newsType'

interface NewsOutletCardProps {
  newsData: NewsDetail
  isHomePage?: boolean
}

const NewsOutletCard = ({
  newsData,
  isHomePage = false,
}: NewsOutletCardProps) => {
  const params = useParams<{ lng: string }>()
  const consentUserValue = useConsentCheck()

  const newsHomeCardRef: MutableRefObject<HTMLDivElement | null> = useRef(null)
  const handleIntersection = () => {
    gtmNewsCardViewTracker(
      newsData?.id,
      String(newsData?.company_id),
      params?.lng ?? 'en',
      newsData?.language_id,
      consentUserValue()
    )
  }

  const observeElement = useIntersectionObserver(handleIntersection)
  const { t } = useTranslation()

  useEffect(() => {
    if (newsHomeCardRef.current) {
      observeElement(newsHomeCardRef.current)
    }
  }, [observeElement])

  return (
    <div>
      <div ref={newsHomeCardRef}>
        <Link
          prefetch={false}
          className={`${isHomePage ? 'mx-6 my-[14px]' : 'shadow-[0_0_6px_0_rgba(0,0,0,0.2)]'}
          rounded-[5px] relative  flex-row flex gap-7 mobile:gap-[0.75rem] p-[10px] mobile:m-0`}
          href={`${window.location.origin}/${params?.lng ?? 'en'}/news/news-detail/${newsData?.id}`}
        >
          <div
            className={`${isHomePage ? '!w-[110px] !h-[110px]' : '!max-w-[197px] !h-[166px]'} `}
          >
            <div className={`relative flex`}>
              <Image
                loading="lazy"
                width={197}
                height={166}
                className={`${isHomePage ? '!w-[100px] !h-[100px]' : '!max-w-[197px] !h-[166px]'} bg-[#ededed] rounded-[5px] mobile:!w-[100px] mobile:!h-[100px] ${newsData?.images?.length > 0 ? 'object-cover' : 'object-contain'}`}
                src={
                  newsData?.images?.length > 0
                    ? newsData?.images[0].url
                    : '/assets/Icons/noImage/noImage2.svg'
                }
                alt=""
              />
              {newsData?.is_sponsored && (
                <div className="absolute bottom-0 left-0  w-full bg-sponsor_color text-black text-xs flex justify-center px-8 items-center h-5 rounded-b-[5px]">
                  <Image
                    src="/assets/Icons/blackStar.png"
                    alt=""
                    width={17.4}
                    height={17.4}
                    className="mr-0 h-4 w-4"
                  />
                  {t('Sponsored')}
                </div>
              )}
            </div>
          </div>
          <NewsResultCardContent newsData={newsData} isHomePage={isHomePage} />
        </Link>
      </div>
      {isHomePage && <Separator />}
    </div>
  )
}

export default NewsOutletCard
