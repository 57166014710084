import { BasicInfoStateType } from '@/app/types/companyProfileTypes'
import { formatReactSelectOption } from '@/components/ui/select/selectStyle'
import { extractNames } from '@/services/companyDetailsService'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'sonner'

const initialState: BasicInfoStateType = {
  loading: false,
  isloading: false,
  error: null,
  infoData: {},
  descriptionList: [],
  newCompanyLogo: null,
  newCompanyName: '',
  newWebsite: '',
  newCountry: '',
  newPhoneNo: '',
  newStreetAddress: '',
  newYearOfStart: '',
  newTurnOver: null,
  newRegistrationNumber: '',
  newTotalEmployee: '',
  newLanguage: [],
  newDescription: '',
  newLanguage_id: '',
  newcommercial_zone: [],
  newPhCode: '',
  newPhDialCode: '',
  newRegion: { id: '', name: '' },
  newProvince: { id: 0, name: '' },
  newCity: { id: '', name: '' },
  newCustomCity: '',
  newActivities: [],
  newCertification: [],
  language: [],
  industry_id: '',
  selectedCountry: {
    name: '',
    id: 0,
    dial_code: '',
    country_code: '',
    emoji: '',
    flag: '',
    has_regions: false,
  },
  country_id: '',
  isInfoUpdated: false,
  isInfoUpdating: false,
  // transport/logistics fields
  services: [],
}
export const getCompanyInfoData = createAsyncThunk<
  any,
  void,
  { rejectValue: object }
>('getCompanyInfoData', async (_, thunkAPI) => {
  try {
    const response = await fetch(`${process.env.API_URL}/companies`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const userData = await response.json()
    return userData
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error })
  }
})
export const updateProfileInfo = createAsyncThunk(
  'updateProfileInfo',
  async (data: FormData, thunkAPI) => {
    try {
      const response = await fetch(`${process.env.API_URL}/companies`, {
        method: 'PATCH',
        body: data,
        credentials: 'include',
      })
      const responseData = await response.json()
      if (!response.ok) {
        toast.error(responseData?.messages)
        return
      }
      toast.success('Details Updated Successfully')
      return responseData
    } catch (error) {
      console.error('Error while updating profile:', error)
      toast.error('Error While Updating')
      return thunkAPI.rejectWithValue('Error while updating profile')
    }
  }
)
export const companyProfileSlice = createSlice({
  name: 'company_profile',
  initialState,
  reducers: {
    setInfoData: (state, action) => {
      state.infoData = action.payload
    },

    setNewCompanyLogo: (state, action) => {
      state.newCompanyLogo = action.payload
    },
    setNewCompanyName: (state, action) => {
      state.newCompanyName = action.payload
    },
    setNewWebsite: (state, action) => {
      state.newWebsite = action.payload
    },
    setNewCountry: (state, action) => {
      state.newCountry = action.payload
    },
    setNewPhoneNo: (state, action) => {
      state.newPhoneNo = action.payload
    },
    setnewStreetAddress: (state, action) => {
      state.newStreetAddress = action.payload
    },
    setNewYearOfStart: (state, action) => {
      state.newYearOfStart = action.payload
    },
    setNewTurnOver: (state, action) => {
      state.newTurnOver = action.payload
    },
    setnewRegistrationNumber: (state, action) => {
      state.newRegistrationNumber = action.payload
    },
    setNewTotalEmployee: (state, action) => {
      state.newTotalEmployee = action.payload
    },
    setNewLanguages: (state, action) => {
      state.newLanguage = action.payload
    },
    setNewDescription: (state, action) => {
      state.newDescription = action.payload
    },
    setNewDescriptionList: (state, action) => {
      state.descriptionList = action.payload
    },
    setDescriptionLangId: (state, action) => {
      state.newLanguage_id = action.payload
    },
    setNewcommercial_zone: (state, action) => {
      state.newcommercial_zone = action.payload
    },
    setNewPhoneCode: (state, action) => {
      state.newPhCode = action.payload
    },
    setNewProvince: (state, action) => {
      state.newProvince = action.payload
    },
    setNewRegion: (state, action) => {
      state.newRegion = action.payload
    },
    setNewCity: (state, action) => {
      state.newCity = action.payload
      state.newCustomCity = action.payload?.name
    },
    setNewActivity: (state, action) => {
      state.newActivities = action.payload
    },
    setNewCertification: (state, action) => {
      state.newCertification = action.payload
    },
    setIsInfoUpdated: (state) => {
      state.isInfoUpdated = false
    },
    setSelectedCountry: (state, action) => {
      state.selectedCountry = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCompanyInfoData.pending, (state) => {
      state.loading = true
      state.isloading = true
    })
    builder.addCase(getCompanyInfoData.fulfilled, (state: any, action) => {
      state.loading = false
      state.infoData = action.payload.company
      state.country_id = state.infoData?.company_address?.country?.id
      state.descriptionList = state.infoData?.descriptions ?? []
      state.industry_id = state.infoData?.industry?.id
      state.newCompanyName = state.infoData?.name
      state.newCompanyLogo = state.infoData?.logo
      state.newWebsite = state.infoData?.company_url
      state.newCountry = state.infoData?.company_address?.country?.id
      state.newPhoneNo = state.infoData?.phone_number
      state.newStreetAddress = state.infoData?.company_address?.street_address
      state.newYearOfStart = state.infoData?.year_of_start ?? ''
      state.newTurnOver = state.infoData?.turnover
      state.newRegistrationNumber = state.infoData?.registration_number
      state.newTotalEmployee =
        state.infoData?.number_of_permanent_employees !== 'Undefined'
          ? state.infoData?.number_of_permanent_employees
          : ''
      state.newLanguage = extractNames(state.infoData?.languages) ?? []
      state.newcommercial_zone =
        extractNames(state.infoData?.commercial_zones) ?? []
      state.newProvince = state.infoData?.company_address?.province ?? {
        id: '',
        name: '',
      }
      state.newRegion = state.infoData?.company_address?.country_region ?? {
        id: '',
        name: '',
      }
      state.newCity =
        state.infoData?.company_address?.city?.name !== null
          ? state.infoData?.company_address?.city
          : { id: '', name: '' }
      state.newCustomCity = state.infoData?.company_address?.city?.name
      state.newPhCode = state.infoData?.phone_code_id
      state.newPhDialCode = state.infoData?.phone_code ?? ''
      state.newActivities = extractNames(state.infoData?.activities) ?? []
      state.newCertification =
        state.infoData?.certifications?.map(
          (item: { id: number; name: string }) => formatReactSelectOption(item)
        ) ?? []
      state.isInfoUpdated = false
      state.selectedCountry = state.infoData?.company_address?.country
      state.isloading = false
    })
    builder.addCase(getCompanyInfoData.rejected, (state, action: any) => {
      state.loading = false
      state.isloading = false
      state.error = action.payload
    })
    builder.addCase(updateProfileInfo.pending, (state) => {
      state.loading = true
      state.isInfoUpdating = true
    })
    builder.addCase(updateProfileInfo.fulfilled, (state) => {
      state.loading = false
      state.isInfoUpdated = true
      state.isInfoUpdating = false
    })
    builder.addCase(updateProfileInfo.rejected, (state, action: any) => {
      state.loading = false
      state.error = action.payload
      state.isInfoUpdating = false
    })
  },
})

export const {
  setInfoData,
  setNewCompanyLogo,
  setNewCompanyName,
  setNewWebsite,
  setNewCountry,
  setNewPhoneNo,
  setnewStreetAddress,
  setNewYearOfStart,
  setNewTurnOver,
  setnewRegistrationNumber,
  setNewTotalEmployee,
  setNewLanguages,
  setNewDescription,
  setDescriptionLangId,
  setNewDescriptionList,
  setNewcommercial_zone,
  setNewPhoneCode,
  setNewRegion,
  setNewCity,
  setNewProvince,
  setNewActivity,
  setNewCertification,
  setIsInfoUpdated,
  setSelectedCountry,
} = companyProfileSlice.actions

export default companyProfileSlice.reducer
