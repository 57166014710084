'use client'

import { useGetHomePageNewsQuery } from '@/app/globalRedux/api/homePageSlice'
import { GetNewsList } from '@/app/globalRedux/myfructidor/newsSlice'
import { useTranslation } from '@/app/i18n/client'
import { Skeleton } from '@/components/ui/skeleton'
import Link from 'next/link'
import { useParams } from 'next/navigation'
import CustomErrorBoundary from '../../ErrorBoundary'
import NewsOutletCard from './NewsOutletCard'

const NewsOutlet = () => {
  const { t } = useTranslation()
  const params = useParams()
  const {
    data: news,
    isLoading,
    isError,
  } = useGetHomePageNewsQuery<{
    data: GetNewsList
    isLoading: boolean
    isError: boolean
  }>({})

  return (
    <div className="w-full mt-[30px] mb-[38px] shadow-[0_0_6px_0_rgba(0,0,0,0.16)] rounded-sm mobile-width mobile:shadow-none mobile:border">
      <div className=" w-full flex justify-between px-6 border-[#ebebeb] border-b border-solid">
        <div className="text-[20px] font-ns_bold py-4 ">{t('News')}</div>
      </div>
      {!isLoading && !isError && news?.news?.length > 0 ? (
        news?.news?.map((newsData, index) => (
          <CustomErrorBoundary key={index}>
            <div
              key={index}
              className="w-full pt-5 mobile:pt-[0] mobile:relative"
            >
              <NewsOutletCard newsData={newsData} isHomePage={true} />
            </div>
          </CustomErrorBoundary>
        ))
      ) : (
        <Skeleton className="h-[800px]" />
      )}
      <Link href={`/${params?.lng}/news`}>
        <div className="text-sm flex w-full items-center justify-center text-sickly_green h-[50px]">
          <span className="cursor-pointer font-ns_bold select-none">
            {t('View All')}
          </span>
        </div>
      </Link>
    </div>
  )
}

export default NewsOutlet
