'use client'
import { sendGTMEvent } from '@next/third-parties/google'

export const GTMNewsDetailTracker = (
  company_id: number,
  current_news_lang: string,
  lang: string,
  news_id: string,
  userId: string
) => {
  sendGTMEvent({
    event: 'news_detail_visit',
    news_id: news_id,
    company_id: company_id,
    lang: lang,
    current_news_lang: current_news_lang,
    current_user_id: userId,
  })
}

export const gtmNewsCardViewTracker = (
  newsId: string,
  companyId: string,
  lang: string,
  current_news_lang: string,
  userId: string
) => {
  sendGTMEvent({
    event: 'news_card_view',
    news_id: newsId,
    company_id: companyId,
    lang: lang,
    current_news_lang: current_news_lang,
    current_user_id: userId,
  })
}
