'use client'
import { gtmFromRedirectedToCompany } from '@/app/[lng]/components/GTMTrackers/GTMCompanyTracker'
import { useGetSearchQuery } from '@/app/globalRedux/api/searchSlice'
import { setSearchInitialLoad } from '@/app/globalRedux/dataSlice'
import {
  setCompanyFiltersToInitialState,
  setCompanySearchTerm,
} from '@/app/globalRedux/filter/companyFilter'
import { clearEventFilters } from '@/app/globalRedux/filter/eventFilter'
import {
  clearFilters,
  setNewsSearchTerm,
} from '@/app/globalRedux/filter/newsFilter'
import {
  setProductFiltersToInitialState,
  setProductSearchTerm,
} from '@/app/globalRedux/filter/productFilter'
import { setSearchTerm } from '@/app/globalRedux/filter/searchStore'
import { RootState } from '@/app/globalRedux/store'
import useConsentCheck from '@/app/hooks/useConsentCheck'
import useLocalizedRouter from '@/app/hooks/useLocalizedRouter'
import { useTranslation } from '@/app/i18n/client'
import {
  BuyerAlert,
  Company,
  News,
  SearchOption,
  SellerOffer,
} from '@/app/types/searchOptionType'
import { decodeHTMLEntities } from '@/lib/utils'
import { Popper } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { styled } from '@mui/system'
import { sendGTMEvent } from '@next/third-parties/google'
import Image from 'next/image'
import { useParams, usePathname, useSearchParams } from 'next/navigation'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const GroupHeader = styled('div')(({}) => ({
  position: 'sticky',
  top: '-8px',
  backgroundColor: '#efefef',
}))

const GroupItems = styled('ul')({
  padding: 0,
})
const CustomPopper = function (props: any) {
  return (
    <Popper
      {...props}
      className=" !ml-[11px] py-2 !w-[600px] !h-fit  headerSearch z-20 mobile:!w-[330px] mobile:!ml-[0]"
      placement="bottom"
    />
  )
}

const tabsToClearSearchResults = [
  'buyers-listing',
  'sales-listing',
  'news',
  'product-company-tab',
  'company-tab',
  'product-tab',
  'news-tab',
]

const languages = ['en', 'fr', 'it', 'es']

const HeaderSearchBar = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const pathName = usePathname()
  const params = useParams()
  const pushWithLocale = useLocalizedRouter()

  const consentUserValue = useConsentCheck()

  const searchText = useSelector(
    (state: RootState) => state.searchData.searchTerm
  )
  const { data } = useGetSearchQuery<{ data: SearchOption }>(
    { searchTerm: searchText, lang: params?.lng ?? 'en' },
    {
      skip: searchText.replace(/\s*$/, '').length == 0,
    }
  )

  const [companyOptions, setCompanyOptions] = useState<Company[]>([])
  const [salesOffer, setSalesOffer] = useState<SellerOffer[]>([])
  const [buyerAlert, setBuyerAlert] = useState<BuyerAlert[]>([])
  const [news, setNews] = useState<News[]>([])
  const [options, setOptions] = useState<any>([])
  const [isSearchResultRedirection, setSearchResultDirection] =
    useState<boolean>(true)
  const searchParams = useSearchParams()
  const isNewsTab = pathName.split('/').includes('news')

  useEffect(() => {
    if (data) {
      const companyGroup: Company[] = (data?.companies ?? [])?.map(
        (option) => ({
          group: 'Companies',
          ...option,
        })
      )
      setCompanyOptions(companyGroup)
      const salesGroup = (data?.sales_offers ?? [])?.map((option) => ({
        group: 'Sales Offers',
        ...option,
      }))

      setSalesOffer(salesGroup)
      const alertGroup = (data?.buyer_alerts ?? [])?.map((option) => ({
        group: `Buyers Alert`,
        ...option,
      }))
      setBuyerAlert(alertGroup)
      const newsGroup = (data?.news ?? [])?.map((option) => ({
        group: 'News',
        ...option,
      }))
      setNews(newsGroup)
    }
  }, [data])

  useEffect(() => {
    if (
      searchText.length === 0 ||
      searchParams.get('searchTerm') !== searchText
    ) {
      dispatch(setProductSearchTerm(searchParams.get('searchTerm') ?? ''))
      dispatch(setCompanySearchTerm(searchParams.get('searchTerm') ?? ''))
      if (!isNewsTab) {
        dispatch(setNewsSearchTerm(searchParams.get('searchTerm') ?? ''))
      }
      dispatch(setSearchTerm(searchParams.get('searchTerm') ?? ''))
    }
    const tab = pathName.split('/')
    if (
      tabsToClearSearchResults.some((item) => tab.includes(item)) &&
      tab.some((item) => languages.includes(item)) &&
      !searchParams.get('searchTerm')
    ) {
      dispatch(setProductSearchTerm(''))
      dispatch(setCompanySearchTerm(''))
      if (!isNewsTab) {
        dispatch(setNewsSearchTerm(''))
      }
      dispatch(setSearchTerm(''))
    }
  }, [pathName])

  useEffect(() => {
    setOptions([
      ...(companyOptions ?? []),
      ...(salesOffer ?? []),
      ...(buyerAlert ?? []),
      ...(news ?? []),
    ])
  }, [companyOptions, salesOffer, buyerAlert, news])

  useEffect(() => {
    setSearchResultDirection(true)
  }, [data])

  const handleClick = (data: any) => {
    searchTermTracker()
    dispatch(setSearchTerm(''))

    if (data?.group == 'Companies') {
      gtmFromRedirectedToCompany(
        'search_bar',
        data?._source?.friendly_url,
        data?._source?.company_id
      )
      if (data?._source?.sector == 'AF Transports') {
        pushWithLocale(
          `/transport_logistics/${data?._source?.friendly_url}/profile?searchTerm`
        )
      } else if (data?._source?.sector == 'AF Pre \u0026 Post Harvest') {
        pushWithLocale(
          `/pre-post-harvest/${data?._source?.friendly_url}/profile`
        )
      } else if (data?._source?.sector == 'AF Production') {
        pushWithLocale(
          `/fruits_and_vegetables/${data?._source?.friendly_url}/profile`
        )
      }
    } else if (data?.group == 'Sales Offers') {
      pushWithLocale(`/sales_offers/${data?._source?.id}`)
    } else if (data?.group == `Buyers Alert`) {
      pushWithLocale(`/buyers_alert/${data?._source?.id}`)
    } else if (data?.group == 'News') {
      pushWithLocale(`/news/news-detail/${data?._source?.uid}`)
    }
  }

  const getOption = (option: any) => {
    switch (option.group) {
      case 'Companies':
        return option?._source?.company_name
      case 'Sales Offers':
        return `${option?._source?.product_name ?? ''} ${option?._source?.product_origin ?? ''} ${option?._source?.created_at ?? ''}`
      case `Buyers Alert`:
        return `${t('Importer from')} ${option?._source?.company_location ?? ''} ${t('is looking for')}  ${option?._source?.product_name ?? ''} ${t('at')} ${option?._source?.created_at ?? ''}`
      case 'News':
        return decodeHTMLEntities(option?._source?.title)
    }
  }

  const searchResultsRedirection = async () => {
    setOptions([])
    dispatch(setProductFiltersToInitialState())
    dispatch(setCompanyFiltersToInitialState())
    dispatch(clearFilters())
    dispatch(clearEventFilters())
    dispatch(setSearchInitialLoad(true))
    dispatch(setProductSearchTerm(searchText.replace(/\s*$/, '')))
    dispatch(setCompanySearchTerm(searchText.replace(/\s*$/, '')))
    dispatch(setNewsSearchTerm(searchText.replace(/\s*$/, '')))
    setSearchResultDirection(true)
    searchTermTracker()
    pushWithLocale(`/search/product-company-tab?searchTerm=${searchText}`)
  }

  //**Search term GTM Tracker */
  const searchTermTracker = () => {
    sendGTMEvent({
      event: 'search_term_event',
      lang: params?.lng ?? 'en',
      current_user_id: consentUserValue(),
      search_term: searchText,
    })
  }
  //
  return (
    <>
      <div className=" h-[57px] bg-[white] rounded-[30px] flex items-center px-1.5 py-2 mobile-width">
        <Image
          loading="lazy"
          width={15.2}
          height={15.2}
          src="/assets/Icons/searchIcon.svg"
          alt=""
          className="ml-[30.7px] mr-[21.1px]"
        />
        <Autocomplete
          clearOnBlur={false}
          id="header-search-bar"
          className={`${options?.length <= 0 ? 'hide' : ''}`}
          forcePopupIcon={false}
          options={options.sort((a: any, b: any) =>
            a.group.localeCompare(b.group)
          )}
          key={pathName}
          groupBy={(option: any) => option.group}
          getOptionLabel={(option) => getOption(option)}
          onInputChange={(event, value) => {
            dispatch(setSearchTerm(value))
          }}
          inputValue={searchText}
          sx={{
            width: '100%',
            border: 'none !important',
            '& .MuiOutlinedInput-root': {
              border: 'none !important',
              borderRadius: '0',
              padding: '0',
              fontWeight: '500 !important',
            },
            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
              border: 'none !important',
            },
          }}
          onChange={(event, value) => {
            setOptions([])
            handleClick(value)
          }}
          renderInput={(params) => (
            <TextField
              onKeyUp={(event) => {
                if (event.key == 'ArrowUp' || event.key == 'ArrowDown') {
                  if (options.length > 0) {
                    setSearchResultDirection(false)
                  }
                }
                if (event.key == 'Enter') {
                  if (isSearchResultRedirection) {
                    searchResultsRedirection()
                  }
                  setOptions([])
                }
              }}
              sx={{
                border: 'none',
                '& .MuiInputBase-input::placeholder': {
                  color: '#5d5d5d',
                  fontSize: '14px',
                  opacity: '1',
                  fontWeight: '500',
                },
              }}
              {...params}
              placeholder={t('Product? Company? Activity? News?')}
            />
          )}
          PopperComponent={CustomPopper}
          renderGroup={(params) => (
            <li key={`${params.key}+header-search`} className=" h-fit">
              <GroupHeader>
                <div className="flex flex-col text-sm font-[500] font-ns_semibold text-dark_text uppercase bg-pale_gray !h-[30px] justify-center p-5 ">
                  {params.group}
                </div>
              </GroupHeader>
              <GroupItems>
                <div className="searchList">{params.children}</div>
              </GroupItems>
            </li>
          )}
        />
        <div
          className="h-full w-[111px] bg-secondary text-white flex justify-center items-center rounded-[30px] px-7 py-5 cursor-pointer mr-[0.2rem]  text-[14px] mobile:w-auto mobile:h-auto mobile:px-[7px] mobile:py-[3px] mobile:text-[10px]"
          onClick={() => searchResultsRedirection()}
        >
          {t('Search')}
        </div>
      </div>
    </>
  )
}

export default HeaderSearchBar
