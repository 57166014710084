import { getCookies } from '@/lib/utils'
import { useSelector } from 'react-redux'
import { RootState } from '../globalRedux/store'

const useConsentCheck = () => {
  const { userId } = useSelector((state: RootState) => state.user)

  const consentUserValue = () => {
    if (userId !== 0) {
      if (getCookies()?.third_party == 'enabled') {
        return String(userId)
      } else {
        return 'not-consented'
      }
    } else {
      return 'non-logged-in'
    }
  }

  return consentUserValue
}

export default useConsentCheck
