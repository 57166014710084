'use client'
import useLocalizedRouter from '@/app/hooks/useLocalizedRouter'
import useRecaptchaV3 from '@/app/hooks/useRecaptvhav3'
import { useTranslation } from '@/app/i18n/client'
import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { RecaptchaResponse, verifyRecaptcha } from '@/services/recaptchaService'
import Image from 'next/image'
import React, { useState } from 'react'
import { toast } from 'sonner'

interface NewsDialogProps {
  email: string
  setEmail: React.Dispatch<React.SetStateAction<string>>
  isEmailValid: boolean
}

const NewsLetterModal = ({
  email,
  setEmail,
  isEmailValid,
}: NewsDialogProps) => {
  const executeRecaptcha = useRecaptchaV3()

  const { t } = useTranslation()
  const pushWithLocale = useLocalizedRouter()
  const [company, setCompany] = useState<string>('')
  const [email_confirmation, setConfirmEmail] = useState<string>('')
  const [english, setEnglish] = useState<boolean>(false)
  const [french, setFrench] = useState<boolean>(false)
  const [spanish, setSpanish] = useState<boolean>(false)
  const [italian, setItalian] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleSubmit = async () => {
    const token = await executeRecaptcha('news_letter')
    if (token) {
      const response: RecaptchaResponse = await verifyRecaptcha(token)
      if (!response?.valid) {
        return
      }
    }

    const languages = []
    if (english) languages.push('en')
    if (french) languages.push('fr')
    if (spanish) languages.push('es')
    if (italian) languages.push('it')

    const formData = {
      newsletter: {
        email: email,
        languages: languages,
        company: company,
      },
    }

    if (!company || !email || !email_confirmation) {
      toast.warning(t('please enter the required fields'))
      return
    }

    if (languages.length === 0) {
      toast.warning(t('please select at least one language'))
      return
    }

    if (email !== email_confirmation) {
      toast.warning(
        t('Emails do not match. Please make sure both emails are the same')
      )
      return
    }

    const isValidEmail = (email: string) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return emailRegex.test(email)
    }

    if (!isValidEmail(email)) {
      toast.warning(t('Please Enter Valid Email'))
      return
    }

    try {
      const response = await fetch(
        `${process.env.API_URL}/newsletter/subscribe`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        }
      )
      const responseData = await response.json()
      if (response.ok) {
        toast.success(t("You're subscribed to the newsletter"))
        setIsOpen(false)
      } else {
        if (responseData.errors[0] === 'Email has already been taken') {
          toast.error(t('Email is already subscribed to the newsletter.'))
        } else {
          toast.error(responseData.errors)
        }
      }
    } catch (error) {
      console.error(t('Error:'), error)
      toast.error(t('An error occurred while submitting the data'))
    }

    setCompany('')
    setEmail('')
    setConfirmEmail('')
    setEnglish(false)
    setFrench(false)
    setSpanish(false)
    setItalian(false)
  }

  const disableCopyPaste = (e: React.ClipboardEvent) => {
    e.preventDefault()
  }

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button
          variant="outline"
          className="border-none flex hover:bg-transparent bg-transparent"
          disabled={!email || !isEmailValid}
          onClick={() => setIsOpen(true)}
        >
          <Image
            loading="lazy"
            width={23.1}
            height={24}
            src="/assets/Icons/sendButton.svg"
            alt=""
            className="mr-[17px] "
          />
        </Button>
      </DialogTrigger>

      <DialogContent
        className="border-0 p-0 !max-w-[1080px] bg-[#fff]"
        onInteractOutside={(e) => {
          e.preventDefault()
        }}
        hideCloseIcon={false}
      >
        <div className="flex mobile:flex-col-reverse">
          <div className="w-[439px] mobile-width">
            <div className="w-[439px] rounded-l-lg bg-no-repeat bg-cover bg-center bg-origin-border  text-center p-5 pt-[90px] flex flex-col demo-background-image mobile:pt-[1rem]  mobile:rounded-[0px] mobile-width mobile:h-[220px] h-[100%]">
              <h2 className="text-[24px] font-ns_bold px-[28px] mobile:text-[18px]">
                {t('largest Leading B2B Website for Fruit and Vegetables')}
              </h2>
              <h4 className=" text-[14px] font-ns_semibold mt-[20px] px-[28px] mobile:text-[13px]">
                {t(
                  'Register your company and unlock exclusive services on Fructidor'
                )}
              </h4>
              <div className="mt-[31px] flex justify-center">
                <div
                  className="text-[16px] text-[#fff] bg-primary px-[21px] py-[9px] rounded-[5px] w-fit font-ns_bold cursor-pointer"
                  onClick={() => {
                    pushWithLocale('/about-us/contact-us')
                  }}
                >
                  {t('Contact Us')}
                </div>
              </div>
            </div>
          </div>
          <div className=" w-full pt-[53px] pr-[93px] pb-[54px] pl-[103px] mobile-width mobile:p-4 mobile:max-h-auto mobile:overflow-scroll">
            <div className="text-center mobile:mt-4">
              <div className="text-[19px] text-secondary pb-[5px] mobile:text-[13px] mobile:pb-[5px]">
                {t('To receive')}{' '}
                <span className="font-ns_bold capitalize">{t('Free')}</span>{' '}
                {t('newsletter every week from fructidor.com')}
              </div>
              <div className="text-[30px] pb-[13px] font-ns_bold mobile:text-[16px] mobile:pb-[5px]">
                {t('Newsletter Registration')}
              </div>
              <div className="text-[14px] pb-[39px] mobile:text-[12px] mobile:pb-[20px]">
                {t(
                  'Stay ahead of the curve with our newsletter! Subscribe now to receive exclusive updates, industry insights, and insider tips delivered straight to your inbox'
                )}
                .
              </div>
            </div>
            <div className="flex flex-col  gap-[10px]">
              <div>
                <Label htmlFor="company" className="required">
                  {t('Company')}
                </Label>
              </div>
              <Input
                id="company"
                value={company}
                type="text"
                name="company"
                onChange={(e) => {
                  setCompany(e.target.value)
                }}
                className="w-full border border-solid border-[black] rounded-[5px]"
              />
            </div>
            <div className="flex flex-col gap-[10px] mt-[11px]">
              <div>
                <Label className="required" htmlFor="email">
                  {t('E-mail')}
                </Label>
              </div>
              <Input
                id="email"
                type="email"
                name="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
                onPaste={disableCopyPaste}
                className="inputField2 w-full border border-solid border-[black] rounded-[5px]"
              />
            </div>
            <div className="flex flex-col gap-[10px] mt-[11px] mb-[11px]">
              <div>
                <Label className="required" htmlFor="confirm_email">
                  {t('Confirm Your E-mail')}
                </Label>
              </div>
              <Input
                id="confirm_email"
                type="text"
                name="email_confirmation"
                value={email_confirmation}
                onChange={(e) => {
                  setConfirmEmail(e.target.value)
                }}
                onPaste={disableCopyPaste}
                className="w-full border border-solid border-[black] rounded-[5px]"
              />
            </div>
            <div>
              <Label className="required">{t('Select Languages')}</Label>
            </div>
            <div className="flex ">
              <div className="flex my-[10px] mr-[20px] mobile:mr-[10px]">
                <Checkbox
                  id="english"
                  className="mr-[5px]"
                  checked={english}
                  onClick={() => {
                    setEnglish(!english)
                  }}
                />
                <Label htmlFor="english">{t('English')}</Label>
              </div>
              <div className="flex my-[10px] mr-[20px]">
                <Checkbox
                  id="itali"
                  className="mr-[5px]"
                  onClick={() => {
                    setItalian(!italian)
                  }}
                  checked={italian}
                />
                <Label htmlFor="itali">{t('Italian')}</Label>
              </div>
              <div className="flex my-[10px] mr-[20px]">
                <Checkbox
                  id="french"
                  className="mr-[5px]"
                  onClick={() => {
                    setFrench(!french)
                  }}
                  checked={french}
                />
                <Label htmlFor="french">{t('French')}</Label>
              </div>
              <div className="flex my-[10px] mr-[20px]">
                <Checkbox
                  id="spanish"
                  className="mr-[5px]"
                  onClick={() => {
                    setSpanish(!spanish)
                  }}
                  checked={spanish}
                />
                <Label htmlFor="spanish">{t('Spanish')}</Label>
              </div>
            </div>

            <div className=" flex justify-center gap-[12px]">
              <Button
                type="submit"
                variant="secondary"
                className="mt-[40px] mobile:mt-[10px]"
                size="sx"
                onClick={() => handleSubmit()}
              >
                {t('REGISTER')}
              </Button>
              <DialogClose asChild>
                <Button
                  className="mt-[40px] bg-[#eaeaea] mobile:mt-[10px]"
                  variant="grayBtn"
                  size="sx"
                >
                  {t('CANCEL')}
                </Button>
              </DialogClose>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default NewsLetterModal
