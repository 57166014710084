import store from '@/app/globalRedux/store'
import { getCookies } from '@/lib/utils'
import { sendGTMEvent } from '@next/third-parties/google'
interface AdBannerTrackerType {
  ad_id?: string
  ad_sid?: string
  ad_schedule_id?: string
  ad_position: string
  ad_page: string
  ad_lang: string
  ad_clicked_id?: string
  ad_company_id: string
}
export const GTMAdBannerViewTracker = ({
  ad_id,
  ad_sid,
  ad_position,
  ad_lang,
  ad_page,
  ad_company_id,
}: AdBannerTrackerType) => {
  const state = store.getState().user
  sendGTMEvent({
    event: 'adbanner_view',
    ad_position: ad_position,
    ad_page: ad_page,
    ad_banner_id: ad_id,
    ad_schedule_id: ad_sid,
    lang: ad_lang,
    company_id: ad_company_id,
    current_user_id:
      state.userId !== 0
        ? getCookies()?.third_party == 'enabled'
          ? state.userId
          : 'not-consented'
        : 'non-logged-in',
  })
}

export const GTMAdBannerClickTracker = ({
  ad_clicked_id,
  ad_schedule_id,
  ad_position,
  ad_lang,
  ad_page,
  ad_company_id,
}: AdBannerTrackerType) => {
  const state = store.getState().user
  sendGTMEvent({
    event: 'adbanner_clicked',
    ad_position: ad_position,
    ad_page: ad_page,
    ad_banner_id: ad_clicked_id,
    ad_schedule_id: ad_schedule_id,
    lang: ad_lang,
    company_id: ad_company_id,
    current_user_id:
      state.userId !== 0
        ? getCookies()?.third_party == 'enabled'
          ? state.userId
          : 'not-consented'
        : 'non-logged-in',
  })
}
