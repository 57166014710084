'use client'

import { useGetHomePageEventsQuery } from '@/app/globalRedux/api/homePageSlice'
import { EventsData } from '../../../types/eventType'
import { RootState } from '@/app/globalRedux/store'
import { useTranslation } from '@/app/i18n/client'
import { Skeleton } from '@/components/ui/skeleton'
import Link from 'next/link'
import { useParams } from 'next/navigation'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import CustomErrorBoundary from '../../ErrorBoundary'
import EventsOutletCard from './EventsOutletCard'

const EventsOutlet = () => {
  const { t } = useTranslation()
  const { loggedIn } = useSelector((state: RootState) => state.user)
  const params = useParams()

  const {
    data: eventsData,
    isLoading,
    isError,
    isFetching,
    refetch: refetchNews,
  } = useGetHomePageEventsQuery<{
    data: EventsData
    isLoading: boolean
    isError: boolean
    isFetching: boolean
  }>({})

  useEffect(() => {
    if (loggedIn) {
      refetchNews() 
    }
  }, [loggedIn, refetchNews]) 

  return (
    <div className="w-full mt-[30px] mb-[38px] shadow-[0_0_6px_0_rgba(0,0,0,0.16)] rounded-sm mobile-width mobile:shadow-none mobile:border">
      <div className="w-full flex justify-between px-6 border-[#ebebeb] border-b border-solid">
        <div className="text-[20px] font-ns_bold py-4">{t('Events')}</div>
      </div>

      {/* Show loading skeleton if still loading or fetching */}
      {(isLoading || isFetching) && !isError ? (
        <Skeleton className="h-[800px]" />
      ) : (
        // If there's no error and events data exists, map over the events
        <>
          {eventsData?.events?.length > 0 ? (
            eventsData.events.slice(0, 5).map((event) => (
              <CustomErrorBoundary key={event.id}>
                <div className="w-full px-5 pt-5 mobile:pt-[0] mobile:px-0 border-b mobile:relative">
                  <EventsOutletCard eventData={event} />
                </div>
              </CustomErrorBoundary>
            ))
          ) : (
            <div className="w-full py-10 text-center text-greyish_brown">
              {t('No events found')}
            </div>
          )}
        </>
      )}

      {/* View All link */}
      <Link href={`/${params?.lng}/events`}>
        <div className="text-sm flex w-full items-center justify-center text-sickly_green h-[50px]">
          <span className="cursor-pointer font-ns_bold select-none">
            {t('View All')}
          </span>
        </div>
      </Link>
    </div>
  )
}

export default EventsOutlet
