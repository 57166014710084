'use client'
import { RootState } from '@/app/globalRedux/store'
import {
  setCompanyName,
  setLoading,
  setLoggedIn,
  setName,
} from '@/app/globalRedux/userStore/userStore'
import useSetLoginBasedOnRole from '@/app/hooks/useLogin'
import { LoginResponse } from '@/app/types/loginResponseType'
import { useParams, usePathname, useRouter } from 'next/navigation'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const AuthorizationWrapper = ({
  loginResponseData,
}: {
  loginResponseData: LoginResponse
}) => {
  const setLoginDetails = useSetLoginBasedOnRole()
  const dispatch = useDispatch()
  const pathName = usePathname()
  const params = useParams()
  const router = useRouter()
  const { loggedIn, loading } = useSelector((state: RootState) => state.user)
  const blockedPath = [
    `${params?.lng}/member/profile`,
    `/${params?.lng}/statistics/dashboard`,
    `/${params?.lng}/statistics/sales_offers`,
    `/${params?.lng}/statistics/buyers_alert`,
    `/${params?.lng}/statistics/news`,
    `/${params?.lng}/statistics/ad_banners`,
    `/${params?.lng}/statistics/mini_website`,
    `/${params?.lng}/myfructidor/edit_profile`,
    `/${params?.lng}/myfructidor/edit-manage-alerts`,
    `/${params?.lng}/myfructidor/edit-product-service`,
    `/${params?.lng}/myfructidor/edit-manage-offers`,
    `/${params?.lng}/myfructidor/edit-manage-alerts`,
    `/${params?.lng}/myfructidor/news`,
    `/${params?.lng}/myfructidor/edit-members`,
    `/${params?.lng}/myfructidor/edit_coverage_area`,
    `/${params?.lng}/myfructidor/edit_pre_post_product_service`,
    `/${params?.lng}/myfructidor/edit_coverage_area`,
  ]

  useEffect(() => {
    if (!loggedIn && !loading) {
      if (blockedPath.includes(pathName)) {
        router.push(`/${params?.lng}`)
      }
      if (!loggedIn) {
        dispatch(setName(null))
        dispatch(setLoggedIn(false))
        dispatch(setCompanyName(null))
      }
    }
  }, [loggedIn])

  useEffect(() => {
    if (loginResponseData && !loggedIn) {
      if (loginResponseData.status !== 'failure') {
        if (pathName.split('/').includes('signup')) {
          router.push('/')
        }
      }
      dispatch(setLoading(false))

      if (loginResponseData?.status == 'success') {
        setLoginDetails(loginResponseData)
      } else if (loginResponseData?.status == 'failure') {
        if (blockedPath.includes(pathName)) {
          window.location.href = '/'
        }
      }
    }
  }, [pathName, loginResponseData])

  return <div></div>
}

export default AuthorizationWrapper
