'use client'

import { setFeatureFlag } from '@/app/globalRedux/featureFlags/featureFlag'
import { useRouter } from 'next/navigation'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { FeatureFlagResponse } from '../Classses/FetatureFlag'
import { Statistics } from '../Classses/Statistcs'

const FeatureFlagStoreInitaitor = ({
  featureFlag,
  url,
}: {
  featureFlag: FeatureFlagResponse
  url: string
}) => {
  const router = useRouter()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!featureFlag?.feature_flags) return

    dispatch(setFeatureFlag(featureFlag.feature_flags))

    const shouldRedirect =
      Statistics.pages.some((statUrl) => statUrl.includes(url)) &&
      !featureFlag.feature_flags.find((item) => item.name === 'statistics')
        ?.flag

    if (shouldRedirect) {
      router.push('/')
    }
  }, [dispatch, featureFlag, router, url])

  return null
}

export default FeatureFlagStoreInitaitor
