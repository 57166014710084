'use client'
import {
  addOrRemoveCompanySelectedActivity,
  setCompanySector,
} from '@/app/globalRedux/filter/companyFilter'
import {
  addOrRemoveFruitVegetableActivity,
  setProductSector,
} from '@/app/globalRedux/filter/productFilter'
import useLocalizedRouter from '@/app/hooks/useLocalizedRouter'
import useScreenSize from '@/app/hooks/useScreenSize'
import { useTranslation } from '@/app/i18n/client'
import CloseIcon from '@mui/icons-material/Close'
import Image from 'next/image'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import CustomErrorBoundary from '../ErrorBoundary'
const activity = [
  { name: 'Grower', icon: 'growIcon.svg' },
  { name: 'Greenhouses Grower', icon: 'growIcon.svg' },
  { name: 'Cooperative', icon: 'cooperativeIcon.svg' },
  { name: 'Exporter', icon: 'exportIcon.svg' },
  { name: 'Packer', icon: 'packerIcon.svg' },
  { name: 'Shipper', icon: 'shipperIcon.svg' },
  { name: 'Broker', icon: 'broker.svg' },
  { name: 'Importer', icon: 'importIcon.svg' },
  { name: 'Buying central', icon: 'buyingCentralIcon.svg' },
  { name: 'Hypermarket', icon: 'hypermarketsIcon.svg' },
  { name: 'Catering', icon: 'cateringIcon.svg' },
  { name: 'Wholesaler/Distributor', icon: 'wholesaleIcon.svg' },
  { name: 'Secondary wholesaler', icon: 'secondaryIcon.svg' },
  { name: 'Processor', icon: 'processorIcon.svg' },
  { name: 'Ripening room', icon: 'ripeningIcon.svg' },
]

const specialCategories = [
  { name: 'Transport/Logistics', icon: 'transportIcon.svg' },
  { name: 'Pre Post Harvest', icon: 'prePostIcon.svg' },
]

const Categories = () => {
  const [showCategoriesBasedOnScreenSize, setShowCategoriesBasedOnScreenSize] =
    useState<boolean>(false)
  const dispatch = useDispatch()
  const screenSize = useScreenSize()
  const { t } = useTranslation()
  const pushWithLocale = useLocalizedRouter()
  const onActivityClick = (activity: string) => {
    dispatch(addOrRemoveCompanySelectedActivity(activity))
    dispatch(addOrRemoveFruitVegetableActivity(activity))
    pushWithLocale('/search/company-tab')
  }

  const sectorClick = (sector: string) => {
    if (sector == 'Transport/Logistics') {
      dispatch(setCompanySector('Transport / Logistics'))
      dispatch(setProductSector('Transport / Logistics'))
    } else if (sector == 'Pre Post Harvest') {
      dispatch(setCompanySector('Pre - Post Harvest'))
      dispatch(setProductSector('Pre - Post Harvest'))
    }
    pushWithLocale(`/search/company-tab`)
  }

  return (
    <div className="h-fit mobile:mt-0 mt-6 mb-4">
      <div className="relative mobile:absolute mobile:flex hidden justify-end mobile:left-4 mobile:top-12">
        {showCategoriesBasedOnScreenSize ? (
          <span
            onClick={() => {
              setShowCategoriesBasedOnScreenSize(
                !showCategoriesBasedOnScreenSize
              )
            }}
          >
            <CloseIcon className=" text-[white] !h-[2rem] !w-[2rem]" />
          </span>
        ) : (
          <Image
            loading="lazy"
            width={23.7}
            height={23.7}
            src="/assets/Icons/barIcon.svg"
            alt=""
            onClick={() => {
              if (screenSize?.width < 768) {
                setShowCategoriesBasedOnScreenSize(
                  !showCategoriesBasedOnScreenSize
                )
              }
            }}
          />
        )}
      </div>

      <div className="">
        {(screenSize?.width >= 768 || showCategoriesBasedOnScreenSize) && (
          <ul className="w-[300px] bg-[white] shadow-[0_3px_6px_0_rgba(0,0,0,0.16)] pt-3 mobile:absolute mobile:left-8 mobile:top-28 mobile:z-[1] relative mb-4">
            <div className="pb-4 pt-1 border-b border-solid border-b-[#f5f5f5]">
              <li className="px-[30px] text-primary">{t('Activities')}</li>
            </div>
            {activity.map((activity, index) => (
              <CustomErrorBoundary key={index}>
                <div
                  key={index}
                  className="py-[10px] border-b-[#f5f5f5] border-b border-solid "
                >
                  <li
                    className="flex text-[#2c2c2c] font-ns_semibold capitalize cursor-pointer text-sm px-[30px] custom-font-class "
                    onClick={() => {
                      onActivityClick(activity.name)
                    }}
                  >
                    <Image
                      priority
                      width={16}
                      height={19}
                      src={`/assets/Icons/${activity.icon}`}
                      alt=""
                      className="mr-3"
                    />
                    {t(activity.name)}
                  </li>
                </div>
              </CustomErrorBoundary>
            ))}

            {specialCategories.map((category, index) => (
              <CustomErrorBoundary key={index}>
                <div
                  key={index}
                  className="relative py-[10px] bg-transparent border-b-[#fff] border-b border-solid"
                >
                  <span className="absolute block bg-orange_tag opacity-10 w-full h-full translate-y-[-10px] z-0"></span>
                  <li
                    className="flex text-[#d28241]  cursor-pointer text-base px-[30px] z-[10] relative"
                    onClick={() => {
                      sectorClick(category.name)
                    }}
                  >
                    <Image
                      width={16}
                      height={19}
                      src={`/assets/Icons/${category.icon}`}
                      alt=""
                      className="mr-3 relative z-[20]"
                    />
                    {t(category.name)}
                  </li>
                </div>
              </CustomErrorBoundary>
            ))}
            {(screenSize?.width >= 768 || showCategoriesBasedOnScreenSize) && (
              <div
                className="w-[10px] flex h-2 absolute mobile:-top-5  mobile:rotate-90"
                style={{
                  borderBottom: '20px solid #fff',
                  borderLeft: '20px solid transparent',
                  borderRight: '0px solid transparent',
                  width: '0',
                  height: '0',
                  content: '',
                  display: 'block',
                }}
              ></div>
            )}
          </ul>
        )}
      </div>
    </div>
  )
}

export default Categories
