import { RootState } from '@/app/globalRedux/store'
import { AdBannerScheduleType } from '@/app/types/adType'
import { getAdsIndices, handleAdClick } from '@/app/utils/AdsUtility'
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
} from '@/components/ui/carousel'
import Autoplay from 'embla-carousel-autoplay'
import Image from 'next/image'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'next/navigation'
import {
  handleAdIntersection,
  useIntersectionObserver,
} from '@/app/hooks/useIntersectionObserver'

interface AdCarouselProps {
  adList?: AdBannerScheduleType[]
  adPosition: string
  dimensions: {
    width: number
    height: number
  }
  fallbackImagePath: string
}

const AdCarousel: React.FC<AdCarouselProps> = ({
  adList,
  adPosition,
  dimensions,
  fallbackImagePath,
}) => {
  const params = useParams<{ lng: string }>()

  const localeLangauge = useSelector(
    (state: RootState) => state.user.localeLangauge
  )
  const [carouselApi, setCarouselApi] = useState<CarouselApi>()
  const [currentCarouselItemIndex, setCurrentCarouselItemIndex] = useState(0)
  const [carouselItemsCount, setCarouselItemsCount] = useState(0)

  const [indicesForAdPosition, setIndicesForAdPosition] = useState<number[]>([])
  const [adsForPosition, setAdsForPosition] = useState<AdBannerScheduleType[]>(
    []
  )

  const handleCarouselMapperClick = (index: number) => {
    if (!carouselApi) return
    carouselApi.scrollTo(index)
    setCurrentCarouselItemIndex(index)
  }

  useEffect(() => {
    if (!carouselApi) return
    setCarouselItemsCount(carouselApi.scrollSnapList().length)
    carouselApi.on('select', () => {
      setCurrentCarouselItemIndex(carouselApi.selectedScrollSnap())
    })
  }, [carouselApi])

  useEffect(() => {
    if (adList) {
      setIndicesForAdPosition(getAdsIndices(adList, adPosition))
    }
  }, [adList, adPosition])

  useEffect(() => {
    if (adList) {
      setAdsForPosition(indicesForAdPosition?.map((ind) => adList[ind]))
    }
  }, [indicesForAdPosition])

  const adPositionRef = useRef<HTMLDivElement>(null)

  const observeElement = useIntersectionObserver(handleAdIntersection)
  useEffect(() => {
    if (adPositionRef.current) {
      observeElement(adPositionRef.current)
    }
  }, [adPositionRef, observeElement])

  return (
    <div
      className={`flex relative mobile-width mobile:mt-3 w-[${dimensions.width}px] h-[${dimensions.height}px] mobile-width cursor-pointer rounded-[3px] overflow-hidden banner-carousel`}
    >
      {adsForPosition?.length > 0 ? (
        <Carousel
          setApi={setCarouselApi}
          opts={{
            align: 'start',
            loop: true,
          }}
          plugins={[
            Autoplay({
              delay: 2400,
              stopOnInteraction: false,
            }),
          ]}
          className={`mobile-width w-[${dimensions.width}px] h-[${dimensions.height}px}`}
        >
          <CarouselContent
            className={`mx-0 mobile-width w-[${dimensions.width}px] h-[${dimensions.height}px}`}
          >
            {adsForPosition.map((ad, index) => (
              <CarouselItem
                key={index}
                className={`px-0 mobile-width w-[${dimensions.width}px] h-[${dimensions.height}px}`}
              >
                <div
                  ref={adPositionRef}
                  data-ad-position={adPosition}
                  data-ad-id={ad?.ad_banner?.id}
                  data-ad-sid={ad?.id}
                  data-ad-page={ad?.page?.name}
                  data-ad-company-id={ad?.ad_banner?.company?.id}
                  data-ad-lang={params?.lng}
                >
                  <Image
                    loading="lazy"
                    width={dimensions.width}
                    height={dimensions.height}
                    src={ad?.ad_banner?.image}
                    alt=""
                    className="ad_banner_gtm object-cover mobile:object-contain w-full h-full"
                    id="ad_click"
                    onClick={() => handleAdClick(ad, params?.lng)}
                  />
                </div>
              </CarouselItem>
            ))}
          </CarouselContent>
          {/* Carousel Dots */}
          <div className="flex absolute flex-col left-7 bottom-10">
            <div className="flex items-start gap-[6px]">
              {carouselItemsCount > 1 &&
                [...Array(carouselItemsCount)].map((_, index) => (
                  <div
                    key={index}
                    className={
                      currentCarouselItemIndex === index
                        ? 'shadow-md w-[12px] h-[12px] bg-sickly_green transition-all ease-in-out delay-150 scale-125 rounded-[10px]'
                        : 'shadow-md w-[12px] h-[12px] bg-[#ffffff] transition-all ease-in-out delay-200 rounded-[10px]'
                    }
                    onClick={() => handleCarouselMapperClick(index)}
                  ></div>
                ))}
            </div>
          </div>
        </Carousel>
      ) : (
        <Image
          width={dimensions.width}
          height={dimensions.height}
          className="object-cover"
          src={fallbackImagePath.replace('{lang}', localeLangauge)}
          alt=""
        />
      )}
    </div>
  )
}

export default AdCarousel
