'use client'
import { RootState } from '@/app/globalRedux/store'
import useConsentCheck from '@/app/hooks/useConsentCheck'
import { getCookies } from '@/lib/utils'
import { sendGTMEvent } from '@next/third-parties/google'
import { useParams, usePathname } from 'next/navigation'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

const GTMPageTracker = () => {
  const pathname = usePathname()
  const params = useParams<{ lng: string }>()
  const { userSubscription, userId } = useSelector(
    (state: RootState) => state.user
  )
  const isAllCookiesEnabled = getCookies()?.third_party == 'enabled'
  const consentUserValue = useConsentCheck()
  useEffect(() => {
    const timer = setTimeout(() => {
      sendGTMEvent({
        event: 'page_view',
        current_day: new Date().getDay(),
        lang: params?.lng ?? 'en',
        current_user_id: consentUserValue(),
        user_subscription: `${
          userSubscription
            ? isAllCookiesEnabled
              ? userSubscription
              : 'not-consented'
            : 'non_logged_in'
        }`,
      })
    }, 1000)

    return () => clearTimeout(timer)
  }, [pathname, isAllCookiesEnabled, userId])

  return null
}

export default GTMPageTracker
