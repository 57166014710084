'use client'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface Activity {
  [key: string]: any
}

interface SupplierBuyer {
  value: string
  activityCount: number
}

interface Region {
  name: string
  count: number
  checked: boolean
}

interface ProductFamilyPayloadList {
  id: number
  name: string
}

interface ProductFilterList {
  productStaticRegion: string[]
  productPpfListedRegion: Region[]
  productTlListedRegion: Region[]
  productFvListedSupplier: SupplierBuyer[]
  productFvListedBuyer: SupplierBuyer[]
  productFvStaticSupplier: string[]
  productFvStaticBuyer: string[]
  productPphListedProductFamily: {
    count: number
    value: string
  }[]
}

const region = [
  { name: 'Europe', count: 0, checked: false },
  { name: 'Americas', count: 0, checked: false },
  { name: 'Asia', count: 0, checked: false },
  { name: 'Africa', count: 0, checked: false },
  { name: 'Oceania', count: 0, checked: false },
]

const initialState: ProductFilterList = {
  productPphListedProductFamily: [],
  productStaticRegion: ['Europe', 'Americas', 'Asia', 'Africa', 'Oceania'],
  productPpfListedRegion: region,
  productTlListedRegion: region,
  productFvListedSupplier: [
    { activityCount: 0, value: 'Broker' },
    { activityCount: 0, value: 'Cooperative' },
    { activityCount: 0, value: 'Exporter' },
    { activityCount: 0, value: 'Greenhouses Grower' },
    { activityCount: 0, value: 'Grower' },
    { activityCount: 0, value: 'Packer' },
    { activityCount: 0, value: 'Ripening room' },
    { activityCount: 0, value: 'SK Logistics' },
    { activityCount: 0, value: 'Shipper' },
  ],
  productFvListedBuyer: [
    { activityCount: 0, value: 'Buying central' },
    { activityCount: 0, value: 'Catering' },
    { activityCount: 0, value: 'Hypermarket' },
    { activityCount: 0, value: 'Importer' },
    { activityCount: 0, value: 'Processor' },
    { activityCount: 0, value: 'Secondary wholesaler' },
    { activityCount: 0, value: 'Supermarket' },
    { activityCount: 0, value: 'Wholesaler/Distributor' },
  ],
  productFvStaticSupplier: [
    'Grower',
    'Greenhouses Grower',
    'Cooperative',
    'Exporter',
    'Shipper',
    'Broker',
    'Packer',
    'Ripening room',
    'SK Logistics',
  ],
  productFvStaticBuyer: [
    'Buying central',
    'Importer',
    'Wholesaler/Distributor',
    'Hypermarket',
    'Catering',
    'Processor',
    'Supermarket',
    'Secondary wholesaler',
  ],
}

export const productFilterList = createSlice({
  name: 'productFilterList',
  initialState,
  reducers: {
    setProductListedBuyerSupplier: (
      state,
      action: PayloadAction<Activity[]>
    ) => {
      // Reset activity counts to 0
      state.productFvListedSupplier.forEach((supplier) => {
        supplier.activityCount = 0
      })
      state.productFvListedBuyer.forEach((buyer) => {
        buyer.activityCount = 0
      })

      // Update activity counts based on payload
      action.payload.forEach((activity) => {
        const [key, count] = Object.entries(activity)[0]

        if (state.productFvStaticSupplier.includes(key)) {
          const supplier = state.productFvListedSupplier.find(
            (supplier) => supplier.value === key
          )
          if (supplier) {
            supplier.activityCount = count
          }
        } else if (state.productFvStaticBuyer.includes(key)) {
          const buyer = state.productFvListedBuyer.find(
            (buyer) => buyer.value === key
          )
          if (buyer) {
            buyer.activityCount = count
          }
        }
      })
    },
    setProductFamilyList: (state, action) => {
      state.productPphListedProductFamily = action.payload
    },

    setProductPphListedRegionCount: (
      state,
      action: PayloadAction<Activity[]>
    ) => {
      state.productPpfListedRegion.forEach((region) => {
        region.count = 0
      })
      action.payload.forEach((activity) => {
        const [key, count] = Object.entries(activity)[0]

        if (state.productStaticRegion.includes(key)) {
          const region = state.productPpfListedRegion.find(
            (region) => region.name === key
          )
          if (region) {
            region.count = count
          }
        }
      })
    },

    setProductTlListedRegionCount: (
      state,
      action: PayloadAction<Activity[]>
    ) => {
      state.productTlListedRegion.forEach((region) => {
        region.count = 0
      })
      action.payload.forEach((activity) => {
        const [key, count] = Object.entries(activity)[0]

        if (state.productStaticRegion.includes(key)) {
          const region = state.productTlListedRegion.find(
            (region) => region.name === key
          )
          if (region) {
            region.count = count
          }
        }
      })
    },
    setProductPphListedRegion: (state, action) => {
      state.productPpfListedRegion = action.payload
    },
    setProductTlListedRegion: (state, action) => {
      state.productTlListedRegion = action.payload
    },
    setProductListedProductFamily: (
      state,
      action: PayloadAction<ProductFamilyPayloadList[]>
    ) => {
      state.productPphListedProductFamily = action.payload?.map((item) => ({
        count: 0,
        value: item.name,
      }))
    },

    setProductListedProductFamilyCount: (
      state,
      action: PayloadAction<
        {
          [key: string]: any
        }[]
      >
    ) => {
      if (!action.payload || action.payload.length === 0) {
        state.productPphListedProductFamily.forEach((item) => {
          item.count = 0
        })
        return
      }

      const product_family = action.payload
      const productOriginalCountList = state.productPphListedProductFamily

      product_family.forEach((family) => {
        const [key, count] = Object.entries(family)[0]
        const item = productOriginalCountList.find(
          (product) => product.value === key
        )
        if (item) {
          item.count = count
        }
      })

      state.productPphListedProductFamily = productOriginalCountList
    },
  },
})

export const {
  setProductListedBuyerSupplier,
  setProductPphListedRegionCount,
  setProductListedProductFamilyCount,
  setProductFamilyList,
  setProductListedProductFamily,
  setProductTlListedRegionCount,
  setProductPphListedRegion,
  setProductTlListedRegion,
} = productFilterList.actions
export default productFilterList.reducer
