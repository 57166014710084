import { Country } from '@/app/types/companySearchType'
import { ContinentType } from '@/app/types/constantDataType'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface EventFilterState {
  searchTerm: string
  month: string
  language: string
  pageNo: number
  order: string
  selectedEventSortOption: string
  clearFilter: boolean
  eventLocationContinents: ContinentType[]
  eventLocation: Country[]
}

const initialState: EventFilterState = {
  searchTerm: '',
  month: '',
  language: 'English',
  pageNo: 1,
  order: 'asc',
  selectedEventSortOption: 'upcoming',
  clearFilter: false,
  eventLocationContinents: [],
  eventLocation: [],
}

export const EventFilterSlice = createSlice({
  name: 'eventFilter',
  initialState,
  reducers: {
    setEventOrder: (state, action: PayloadAction<string>) => {
      state.order = action.payload
    },
    setEventPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload
    },
    setEventSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload
    },
    setLanguages: (state, action: PayloadAction<string>) => {
      state.language = action.payload
    },
    setAddOrRemoveMonth: (state, action: PayloadAction<string>) => {
      if (state.month == action.payload) {
        state.month = ''
      } else {
        state.month = action.payload
      }
    },
    setEventSortSelectedOption: (state, action) => {
      state.selectedEventSortOption = action.payload
    },
    clearEventFilters: () => {
      return {
        ...initialState,
      }
    },
    addOrRemoveEventLocationContinents: (
      state,
      action: PayloadAction<ContinentType>
    ) => {
      if (
        state.eventLocationContinents.find(
          (item) => item.id === action.payload.id
        )
      ) {
        state.eventLocationContinents = state.eventLocationContinents.filter(
          (item) => item.id !== action.payload.id
        )
      } else {
        state.eventLocationContinents.push(action.payload)
      }
    },
    addOrRemoveEventLocation: (state, action: PayloadAction<Country>) => {
      if (state.eventLocation.find((item) => item.id === action.payload.id)) {
        state.eventLocation = state.eventLocation.filter(
          (item) => item.id !== action.payload.id
        )
      } else {
        state.eventLocation.push(action.payload)
      }
    },
    setEventLocation: (state, action: PayloadAction<Country[]>) => {
      state.eventLocation = action.payload
    },
  },
})

export const {
  setEventOrder,
  setEventSearchTerm,
  setEventPageNo,
  setEventSortSelectedOption,
  setAddOrRemoveMonth,
  setLanguages,
  clearEventFilters,
  addOrRemoveEventLocationContinents,
  addOrRemoveEventLocation,
  setEventLocation,
} = EventFilterSlice.actions

export default EventFilterSlice.reducer
