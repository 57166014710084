import { Contact } from '../types/ContactType'

export const sortByName = (arr: any) => {
  return arr?.sort((a: any, b: any) => {
    if (a.name < b.name) {
      return -1
    }
    if (a.name > b.name) {
      return 1
    }
    return 0
  })
}

export const formateContactsList = (member: Contact) => {
  return `${member?.first_name ?? ''} ${member?.last_name && !member?.is_lastname_confidential ? member?.last_name : ''} ${member?.department?.name && !member?.is_department_confidential ? ` - ${member?.department?.name}` : ''} ${member?.position ? ` - ${member?.position} ` : ''}`
}
