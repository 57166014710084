import { Country } from '@/app/types/companySearchType'
import { ContinentType } from '@/app/types/constantDataType'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface CommonFilterType {
  searchTerm: string
  companyLocationContinents: ContinentType[]
  companyLocation: Country[]
  productLocationContients: ContinentType[]
  productLocation: Country[]
}

export const commonFilterInitalState = {
  searchTerm: '',
  companyLocationContinents: [],
  companyLocation: [],
  productLocationContients: [],
  productLocation: [],
}
const initialState: CommonFilterType = {
  ...commonFilterInitalState,
}

export const commonFilterSlice = createSlice({
  name: 'commonFilter',
  initialState,
  reducers: {
    setSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload
    },
    addOrRemoveCompanyLocationContinents: (
      state,
      action: PayloadAction<ContinentType>
    ) => {
      if (
        state.companyLocationContinents.find(
          (item) => item.id === action.payload.id
        )
      ) {
        state.companyLocationContinents =
          state.companyLocationContinents.filter(
            (item) => item.id !== action.payload.id
          )
      } else {
        state.companyLocationContinents.push(action.payload)
      }
    },
    addOrRemoveCompanyLocation: (state, action: PayloadAction<Country>) => {
      if (state.companyLocation.find((item) => item.id === action.payload.id)) {
        state.companyLocation = state.companyLocation.filter(
          (item) => item.id !== action.payload.id
        )
      } else {
        state.companyLocation.push(action.payload)
      }
    },
    setCompanyLocation: (state, action: PayloadAction<Country[]>) => {
      state.companyLocation = action.payload
    },

    addOrRemoveProductLocationContinents: (
      state,
      action: PayloadAction<ContinentType>
    ) => {
      if (
        state.productLocationContients.find(
          (item) => item.id === action.payload.id
        )
      ) {
        state.productLocationContients = state.productLocationContients.filter(
          (item) => item.id !== action.payload.id
        )
      } else {
        state.productLocationContients.push(action.payload)
      }
    },
    addOrRemoveProductLocation: (state, action: PayloadAction<Country>) => {
      if (state.productLocation.find((item) => item.id === action.payload.id)) {
        state.productLocation = state.productLocation.filter(
          (item) => item.id !== action.payload.id
        )
      } else {
        state.productLocation.push(action.payload)
      }
    },
    setProductLocation: (state, action: PayloadAction<Country[]>) => {
      state.productLocation = action.payload
    },
    setCommonFilterToInitalState: () => {
      return {
        ...initialState,
      }
    },
  },
})

export const {
  setSearchTerm,
  addOrRemoveCompanyLocation,
  addOrRemoveCompanyLocationContinents,
  setCompanyLocation,
  addOrRemoveProductLocationContinents,
  addOrRemoveProductLocation,
  setProductLocation,
  setCommonFilterToInitalState,
} = commonFilterSlice.actions

export default commonFilterSlice.reducer
