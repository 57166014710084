import {
  Language,
  ListType,
  OptionsType,
  SelectOptionType,
} from '@/app/types/constantDataType'
import { StylesConfig } from 'react-select'

export const colourStyles: StylesConfig<SelectOptionType, true> = {
  control: (styles, { isDisabled }) => ({
    ...styles,
    width: '100%',
    fontSize: '14px',
    backgroundColor: 'white',
    cursor: isDisabled ? 'not-allowed' : 'default',
    border: '2px solid #e0e0e0',
    boxShadow: 'none',
    ':hover': {
      border: '2px solid #e0e0e0',
    },
  }),
  option: (styles, { isDisabled }) => {
    return {
      ...styles,
      width: '100%',
      fontSize: '14px',
      color: 'black',
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':hover': {
        ...styles[':hover'],
        backgroundColor: '#f1f5f9',
      },
      background: '#ffffff',
    }
  },
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: '#f5f5f5',
      border: '1px solid #e0e0e0',
      borderRadius: '3px',
    }
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: 'balck',
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: 'orange',
    ':hover': {
      backgroundColor: 'none',
    },
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    alignItems: 'end',
    border: 'none',
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    backgroundColor: 'none',
  }),
  menu: (styles) => ({
    ...styles,
    borderRadius: '3px',
  }),
  menuList: (styles) => ({
    ...styles,
    padding: '0px',
    scrollbarWidth: 'thin',
  }),
  valueContainer: (styles) => ({
    ...styles,
    color: 'grey',
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: '#e0e0e0',
    ':hover': {
      color: '#e0e0e0',
    },
  }),
  clearIndicator: (styles) => ({
    ...styles,
    color: '#e0e0e0',
    ':hover': {
      color: '#e0e0e0',
    },
  }),
  loadingIndicator: (styles) => ({
    ...styles,
    fontSize: '14px',
    padding: '0.35rem',
  }),
  noOptionsMessage: (styles) => ({
    ...styles,
    fontSize: '14px',
    padding: '0.35rem',
  }),
  groupHeading: (styles) => ({
    ...styles,
    fontSize: '14px',
    color: 'black',
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    height: '30px',
    backgroundColor: '#e0e0e0',
  }),
}

export const formatSelectValue = (item: ListType | Language): SelectOptionType => {
  return {
    id: Number(item?.id),
    value: item?.name ?? '',
    label: item?.name ?? '',
    other: {
      ...item,
    },
  }
}

export const formatReactSingleSelectData = (
  option: ListType[] | OptionsType[]
): SelectOptionType[] => {
  return option?.map((item) => ({
    id: Number(item?.id),
    value: String(item?.id),
    label: item?.name,
    other: item,
  }))
}

export const generateValueLabelFormate = (data: any) => {
  return (
    data?.map((item: any) => ({
      label: item?.name,
      value: item?.id,
    })) ?? []
  )
}

export const generateSingleValueLabelFormate = (data: any) => {
  return [
    {
      label: data?.name,
      value: data?.id,
    },
  ]
}

export const formatReactSelectOption = (data: {
  id: number
  name: string
}): SelectOptionType => {
  return { id: data?.id, value: data?.name, label: data?.name }
}
