import { DescriptionType } from '@/app/types/companyProfileTypes'
import { CountryType, Language, ListType } from '@/app/types/constantDataType'

export const allFields = [
  { name: 'logo', label: 'newCompanyLogo' },
  { name: 'name', label: 'newCompanyName' },
  { name: 'company_url', label: 'newWebsite' },
  { name: 'country_id', label: 'newCountry' },
  { name: 'phone_number', label: 'newPhoneNo' },
  { name: 'street_address', label: 'newStreetAddress' },
  { name: 'year_of_start', label: 'newYearOfStart' },
  { name: 'turnover', label: 'newTurnOver' },
  { name: 'registration_number', label: 'newRegistrationNumber' },
  { name: 'number_of_permanent_employees', label: 'newTotalEmployee' },
  { name: 'language', label: 'newLanguage' },
  { name: 'description', label: 'newDescription' },
  { name: 'language_id', label: 'newLanguage_id' },
  { name: 'province_id', label: 'newProvince' },
  { name: 'region', label: 'newRegion' },
  { name: 'city', label: 'newCity' },
  { name: 'phone_code_id', label: 'newPhCode' },
  { name: 'activity', label: 'newActivities' },
  { name: 'certification', label: 'newCertification' },
  { name: 'commercial_zone', label: 'newcommercial_zone' },
]

export const groupData = async (
  companyProfile: any,
  languages: Language[],
  activityList: ListType[],
  certificationsList: ListType[],
  countriesList: CountryType[],
  descriptionList: DescriptionType[]
) => {
  const companydata = new FormData()
  let addressAppended = false
  try {
    await Promise.all(
      allFields.map(async (field) => {
        const value = companyProfile[field.label]
        switch (field?.name) {
          case 'name':
          case 'phone_number':
          case 'company_url':
          case 'logo':
          case 'year_of_start':
          case 'turnover':
          case 'registration_number':
          case 'number_of_permanent_employees':
          case 'phone_code_id':
            if (field?.name === 'logo' && typeof value !== 'string' && value) {
              companydata.append(`company[${field?.name}]`, value)
            } else if (field.name !== 'logo' && value) {
              companydata.append(`company[${field?.name}]`, value)
            }
            break
          case 'country_id':
          case 'province_id':
          case 'region':
          case 'city':
          case 'street_address':
            if (!addressAppended) {
              if (companyProfile.newStreetAddress) {
                companydata.append(
                  `company[company_address_attributes][street_address]`,
                  companyProfile.newStreetAddress
                )
              }
              if (companyProfile.newCountry) {
                companydata.append(
                  `company[company_address_attributes][country_id]`,
                  companyProfile.newCountry
                )
              }
              if (companyProfile?.newProvince?.id > 0) {
                companydata.append(
                  `company[company_address_attributes][province_id]`,
                  companyProfile?.newProvince?.id
                )
              }
              if (companyProfile?.newCity?.id) {
                companydata.append(
                  `company[company_address_attributes][city_id]`,
                  companyProfile?.newCity?.id
                )
              } else {
                companydata.append(
                  `company[company_address_attributes][custom_city]`,
                  companyProfile?.newCustomCity ?? ''
                )
              }
              if (companyProfile?.newRegion) {
                companydata.append(
                  `company[company_address_attributes][region_id]`,
                  companyProfile?.newRegion?.id
                )
              }
            }
            addressAppended = true
            break
          case 'language':
          case 'activity':
          case 'certification':
          case 'commercial_zone': {
            const list = {
              language: languages,
              activity: activityList,
              certification: certificationsList,
              commercial_zone: countriesList,
            }
            if (field?.name === 'certification') {
              value?.forEach((id: any) => {
                if (id?.id) {
                  companydata.append(
                    `company[${field?.name}_ids][]`,
                    String(id?.id)
                  )
                }
              })
            } else {
              const filteredData = list[field?.name].filter((item: any) =>
                value?.includes(item?.name)
              )

              filteredData.forEach((id: any) => {
                value?.forEach((item: any) => {
                  if (id.name === item) {
                    if (id) {
                      companydata.append(
                        `company[${field?.name}_ids][]`,
                        id?.id
                      )
                    }
                  }
                })
              })
            }
            break
          }
        }
      })
    )
    if (descriptionList.length > 0) {
      descriptionList.forEach((item) => {
        if (item?.language) {
          companydata.append(
            `company[company_descriptions_attributes][${item?.language?.id}][content]`,
            item?.content
          )
        }
      })
    }
    return companydata
  } catch (error) {
    console.error('Error while grouping data:', error)
    throw error
  }
}

export function extractNames(data: any) {
  return data?.map((item: any) => item.name)
}

export function extractIds(data: any) {
  return data?.map((item: any) => item.id)
}

export const getCompanyProfileData = async (id: string | string[]) => {
  try {
    const response = await fetch(
      `${process.env.API_URL}/companies/${id}/profile`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    const reponseData = await response.json()
    return reponseData
  } catch (error) {
    console.error('Error fetching data:', error)
  }
}

export const askQuote = async (
  companyId: string,
  productId: number,
  message: string
) => {
  const response = await fetch(
    `${process.env.API_URL}/companies/${companyId}/ask_quote`,
    {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        product_id: productId,
        message: message,
      }),
    }
  )
  const data = await response.json()
  return data
}

export const productCountryCard = async (id: string | string[]) => {
  try {
    const response = await fetch(
      `${process.env.API_URL}/companies/${id}/logistics`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    const data = await response.json()
    return data
  } catch (error) {
    console.error('Error fetching data:', error)
  }
}

export const productStatsData = async (id: string) => {
  try {
    const response = await fetch(
      `${process.env.API_URL}/companies/${id}/stats`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    const data = await response.json()
    return data
  } catch (error) {
    console.error('Error fetching data:', error)
  }
}

export const companyProductCardData = async (
  id: string,
  lng: string | string[]
) => {
  try {
    const response = await fetch(
      `${process.env.API_URL}/companies/${id}/company_products?lang=${lng}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    const data = await response.json()
    return data
  } catch (error) {
    console.error('Error fetching data:', error)
  }
}

// List all the companies

export const companyOffersData = async (id: string, page: number) => {
  try {
    const response = await fetch(
      `${process.env.API_URL}/companies/${id}/offers?page=${page}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    const data = await response.json()
    return data
  } catch (error) {
    console.error('Error fetching data:', error)
  }
}

export const companyNewsData = async (
  id: string | string[],
  page: number,
  lng: string | string[],
  order?: string
) => {
  try {
    const response = await fetch(
      `${process.env.API_URL}/companies/${id}/news?page=${page}&order=${order ?? 'desc'}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    const data = await response.json()
    return data
  } catch (error) {
    console.error('Error fetching data:', error)
  }
}

export const validateCompanyProfile = (companyProfile: any) => {
  if (
    companyProfile.newCompanyName !== '' &&
    companyProfile.newStreetAddress !== '' &&
    companyProfile.newPhoneNo !== '' &&
    companyProfile.newActivities.length > 0
  ) {
    return true
  } else {
    return false
  }
}
