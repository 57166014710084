import { useDispatch } from 'react-redux'
import { setCommonFilterToInitalState } from '../globalRedux/filter/commonFilter'
import { clearFilters } from '../globalRedux/filter/newsFilter'
import { setProductFiltersToInitialState } from '../globalRedux/filter/productFilter'
import useLoginRouter from './useLoginRouter'

const useNavItems = () => {
  const navigateToLogin = useLoginRouter()
  const dispatch = useDispatch()

  const navItems = () => {
    return [
      {
        href: '/buyers-listing',
        text: 'Buyers Alert',
        pathIndentifier: ['buyers-listing', 'buyers_alert'],
        id: 'buyersAlert',
        onClick: () => {
          dispatch(setCommonFilterToInitalState())
          dispatch(setProductFiltersToInitialState())
        },
      },
      {
        href: '/sales-listing',
        text: 'Sales Offers',
        pathIndentifier: ['sales-listing', 'sales_offers'],
        id: 'salesOffers',
        onClick: () => {
          dispatch(setCommonFilterToInitalState())
          dispatch(setProductFiltersToInitialState())
        },
      },
      {
        href: '/news',
        text: 'News',
        pathIndentifier: ['news'],
        id: 'news',
        onClick: () => {
          dispatch(clearFilters())
          dispatch(setCommonFilterToInitalState())
        },
      },
      {
        href: `/`,
        text: 'Register your Company',
        id: 'register',
        condition: (userName: string) => !userName,
        onClick: () => navigateToLogin('signup'),
      },
      {
        href: '/events',
        text: 'Events',
        pathIndentifier: ['events'],
        id: 'events',
      },
      {
        href: '/solutions/for_buyers',
        text: 'Solutions',
        pathIndentifier: ['solutions'],
        id: 'solutions',
      },
      {
        href: '/about-us',
        text: 'About Us',
        pathIndentifier: ['about-us'],
        id: 'aboutUs',
      },
      {
        href: '/advertise_page/website_ad',
        text: 'Advertise',
        pathIndentifier: ['advertise_page'],
        id: 'advertise',
      },
    ]
  }
  return navItems
}
export default useNavItems
