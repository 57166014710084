import acceptLanguage from 'accept-language'
import { NextRequest, NextResponse } from 'next/server'
import { fallbackLng, languages } from './app/i18n/settings'
import { companyProfileRedirection } from './lib/redirectionUtils'

acceptLanguage.languages(languages)
type StatusType = 'success' | 'error'
export const config = {
  matcher: [
    '/((?!api|uapi|_next/static|_next/image|assets|favicon.ico|robots.txt|opengraph-image.png|sw.js).*)',
  ],
}

const domainLanguageMap: Record<string, string> = {
  'st-fr-fructidor.app.ubq.network': 'fr',
  'pr-es-fructidor.app.ubq.network': 'es',
  'pr-it-fructidor.app.ubq.network': 'it',
  'pr-fr-fructidor.app.ubq.network': 'fr',
  'pr-en-fructidor.app.ubq.network': 'en',
  'fructidor.fr': 'fr',
  'fructidor.it': 'it',
  'fructidor.es': 'es',
  'localhost:3001': 'en',
}

export const getLanguageFromPathname = (pathname: string) => {
  const matchedLanguage = languages.find((lang) =>
    pathname?.startsWith(`/${lang}`)
  )
  return matchedLanguage || 'en'
}

export const removeLanguageCode = (url: string): string => {
  const pathParts = url.split('/')
  if (languages.includes(pathParts[1])) pathParts.splice(1, 1)
  return pathParts.join('/')
}

const redirectToNews = async (request: NextRequest) => {
  const params = new URLSearchParams(request?.nextUrl?.search)
  const queryNewsId = params.get('idn') ?? ''
  let response
  try {
    response = await fetch(
      `${process.env.API_URL}/news/fetch_uid/?id=${queryNewsId}`
    )

    if (!response.ok) {
      return undefined
    }
  } catch (error) {
    return undefined
  }

  const result: { status: StatusType; uid: number } = await response.json()
  if (result.status == 'error' || queryNewsId == String(result?.uid)) {
    return `${request?.nextUrl.origin}/news`
  }

  return `${request?.nextUrl.origin}/news/news-detail/${result?.uid}`
}

const redirectToBuyerAlert = async (request: NextRequest) => {
  const params = new URLSearchParams(request?.nextUrl?.search)
  const querySalesOfferId = params.get('id') ?? ''
  let response
  try {
    response = await fetch(
      `${process.env.API_URL}/buyer_alerts/fetch_id/?id=${querySalesOfferId}`
    )
    if (!response.ok) {
      return undefined
    }
  } catch (error) {
    return undefined
  }

  const result: { status: StatusType; id: number; lang: string } =
    await response.json()
  if (result.status == 'error' || querySalesOfferId == String(result?.id)) {
    return `${request?.nextUrl.origin}/buyers-listing`
  }

  return `${request?.nextUrl.origin}/${result?.lang}/buyers_alert/${result?.id}`
}

const redirectToCompany = async (request: NextRequest) => {
  const params = new URLSearchParams(request?.nextUrl?.search)
  const queryCompanyID = params.get('idadr')
  const lng = getLanguageFromPathname(request?.nextUrl?.pathname) || 'en'
  let response

  try {
    response = await fetch(
      `${process.env.API_URL}/companies/fetch_idar/?id=${queryCompanyID}`
    )
    if (!response.ok) {
      return undefined
    }
  } catch (error) {
    return undefined
  }
  const result: {
    status: StatusType
    friendly_url: string
    industry: string
  } = await response.json()
  if (result.status == 'error') {
    return undefined
  }

  return `${request?.nextUrl.origin}${companyProfileRedirection(result?.industry, result?.friendly_url, lng)}`
}

const checkforOldSiteRedirection = async (request: NextRequest) => {
  const NEWS_MATCH = '?idn='
  const BUYER_MATCH = 'OfferDetails.aspx?id='
  const COMPANY_MATCH = 'company.aspx?idadr='

  if (request?.nextUrl?.href?.includes(BUYER_MATCH)) {
    return redirectToBuyerAlert(request)
  }

  if (request?.nextUrl?.href?.includes(NEWS_MATCH)) {
    return redirectToNews(request)
  }
  if (request?.nextUrl?.href?.includes(COMPANY_MATCH)) {
    return redirectToCompany(request)
  }
  return undefined
}

export async function middleware(request: NextRequest) {
  const { pathname } = request.nextUrl
  const domain = request.headers.get('x-forwarded-host') ?? ''
  const headers = new Headers(request.headers)
  headers.set('current-path', request.nextUrl.pathname)

  const oldToNewSiteURl = (await checkforOldSiteRedirection(request)) ?? ''
  if (oldToNewSiteURl) {
    return NextResponse.redirect(oldToNewSiteURl)
  }

  // Redirect www.fructidor.com to fructidor.com
  if (domain == 'www.fructidor.com') {
    return NextResponse.redirect('https://fructidor.com/en')
  }

  const cookieStore = request.cookies
  const fructidorUI = cookieStore.get('fructidor_uid')?.value
  const COOKIE_LANG = cookieStore.get('lang')?.value
  const lng = getLanguageFromPathname(pathname) ?? fallbackLng

  const response = NextResponse.next({ headers })
  if (lng !== COOKIE_LANG) {
    response.cookies.set({
      name: 'lang',
      value: lng,
      path: '/',
      httpOnly: true,
      sameSite: 'lax',
    })
  }

  if (!fructidorUI) {
    response.cookies.set({
      name: 'fructidor_uid',
      value: crypto.randomUUID(),
      path: '/',
      httpOnly: true,
      sameSite: 'lax',
    })
  }

  // Check if pathname already contains a language prefix
  const hasLangInPath = languages.some((loc) => pathname?.startsWith(`/${loc}`))
  const domainLanguage = domainLanguageMap[domain]

  if (pathname.includes('icon') || pathname.includes('chrome')) {
    return response
  }

  // Handle language-based redirection based on domain mapping
  if (domainLanguage && !request.nextUrl.pathname?.startsWith('/_next')) {
    if (!hasLangInPath || (hasLangInPath && lng !== domainLanguage)) {
      const searchParams = request.url.split('?')?.[1]
      return NextResponse.redirect(
        new URL(
          `/${domainLanguage}/${removeLanguageCode(pathname)}?${searchParams ? searchParams : ''}`,
          request.url
        )
      )
    }
  } else if (
    !hasLangInPath &&
    !request.nextUrl.pathname?.startsWith('/_next')
  ) {
    const urlPath = `/${lng}${pathname}`
    return NextResponse.redirect(new URL(urlPath, request.url))
  }

  return response
}
