'use client'
import { LanguageBase } from './LanguageBase'
import { useParams, usePathname } from 'next/navigation'

export const LanguageDropDown = () => {
  const params = useParams()
  const pathnameParts = usePathname().split('/')
  let pathName = usePathname()
  if (
    pathnameParts.length > 1 &&
    ['en', 'it', 'fr', 'es'].includes(pathnameParts[1])
  ) {
    pathName = pathnameParts.slice(2).join('/')
  }
  return <LanguageBase lng={String(params?.lng) ?? 'en'} path={pathName} />
}
