'use client'
import { sendGTMEvent } from '@next/third-parties/google'

export const GTMShareTracker = (
  share_item: string,
  share_id: string,
  user_id: number
) => {
  sendGTMEvent({
    event: 'share_event',
    share_item: share_item,
    share_id: share_id,
    current_user_id: `${user_id ? user_id : 'non_logged_in'}`,
  })
}
