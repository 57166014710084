import en from '@/app/i18n/locales/en/translation.json'
import es from '@/app/i18n/locales/es/translation.json'
import fr from '@/app/i18n/locales/fr/translation.json'
import it from '@/app/i18n/locales/it/translation.json'

import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'
type TranslationKeys = keyof typeof en &
  keyof typeof es &
  keyof typeof fr &
  keyof typeof it
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function endsWithLanguageCode(str: string) {
  const regex = /\/(en|es|fr|it)$/
  return regex.test(str)
}

export const handleUrlClick = (url: string) => {
  if (
    !url?.startsWith('mailto:') &&
    !url?.startsWith('http://') &&
    !url?.startsWith('https://')
  ) {
    url = `https://${url}`
  }
  window.open(url, '_blank')
}

export const decodeHTMLEntities = (text: string) => {
  const parser = new DOMParser()
  const dom = parser.parseFromString(
    `<!doctype html><body>${text}`,
    'text/html'
  )
  return dom.body.textContent
}

export const industryMapping = (industry: string) => {
  switch (industry) {
    case 'Fruit & Vegetable':
      return 'AF production'
    case 'Pre - Post Harvest':
      return 'AF Pre & Post Harvesting'
    case 'Transport / Logistics':
      return 'AF Transports'
  }
  return 'AF production'
}

export const getDomain = () => {
  if (window.location.host == 'marketplace.hashagile.com') {
    return 'hashagile.com'
  } else if (window.location.hostname == 'localhost') {
    return 'localhost'
  }

  return window.location.hostname
}

export const getCookies = () => {
  if (typeof window !== 'undefined') {
    const pairs = document?.cookie.split(';')
    const cookies: any = {}
    for (let i = 0; i < pairs.length; i++) {
      const pair = pairs[i].split('=')
      cookies[(pair[0] + '').trim()] = unescape(pair.slice(1).join('='))
    }
    return cookies
  }
  return undefined
}

export const getTranslation = (lng: string, text: TranslationKeys) => {
  switch (lng) {
    case 'en':
      return en[text]
    case 'es':
      return es[text]
    case 'fr':
      return fr[text]
    case 'it':
      return it[text]
    default:
      return en[text]
  }
}
// SEO Site Links
export const siteStructuredLinks = (siteLinkURL: string) => {
  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        item: {
          '@id': `${siteLinkURL}/about-us`,
          name: 'About Us',
          description: 'Learn more about our company and services.',
        },
      },
      {
        '@type': 'ListItem',
        position: 2,
        item: {
          '@id': `${siteLinkURL}/about-us/contact-us`,
          name: 'Contact Us',
          description: 'Get in touch with our team.',
        },
      },
      {
        '@type': 'ListItem',
        position: 3,
        item: {
          '@id': `${siteLinkURL}/buyers-listing`,
          name: 'Buyers Alert',
          description: 'Stay informed about buyer alerts and updates.',
        },
      },
      {
        '@type': 'ListItem',
        position: 4,
        item: {
          '@id': `${siteLinkURL}/sales-listing`,
          name: 'Sales Offers',
          description: 'Explore our latest sales offers on products.',
        },
      },
      {
        '@type': 'ListItem',
        position: 5,
        item: {
          '@id': `${siteLinkURL}/news`,
          name: 'News',
          description: 'Get the latest news and updates from our company.',
        },
      },
      {
        '@type': 'ListItem',
        position: 6,
        item: {
          '@id': `${siteLinkURL}/events`,
          name: 'Events',
          description: 'Stay informed about Events.',
        },
      },
      {
        '@type': 'ListItem',
        position: 7,
        item: {
          '@id': `${siteLinkURL}/solutions/for_buyers`,
          name: 'Solutions for Buyers',
          description: 'Discover solutions tailored for buyers.',
        },
      },
      {
        '@type': 'ListItem',
        position: 8,
        item: {
          '@id': `${siteLinkURL}/advertise_page/website_ad/ad_home_page`,
          name: 'Advertise with Us',
          description: 'Learn about our advertising options and services.',
        },
      },
      {
        '@type': 'ListItem',
        position: 9,
        item: {
          '@id': `${siteLinkURL}/terms_and_conditions`,
          name: 'Terms of Service and Conditions',
          description:
            'This Terms of service and conditions will help you understand how we collect and use information in Fructidor.com',
        },
      },
    ],
  }
}

export const navigateToLogin = (redirectTo: string, path: string) => {
  let suffixRedirectURL = '/auth/login'
  if (redirectTo == 'signup') {
    suffixRedirectURL += '?screen_hint=signup'
  }

  if (process.env.NODE_ENV === 'development') {
    return `${process.env.API_LOCALHOST_ORIGIN_URL}${suffixRedirectURL}`
  } else {
    return path + suffixRedirectURL
  }
}

export const ensureUrlHttpPrefix = (url: string) => {
  const downcasedUrl = url?.toLowerCase()

  if (
    url &&
    !downcasedUrl?.startsWith('http://') &&
    !downcasedUrl?.startsWith('https://')
  ) {
    return 'https://' + url
  }
  return url
}

export const packagingAttributeMap: Record<string, string> = {
  en: 'packaging',
  it: 'packaging_it',
  es: 'packaging_es',
  fr: 'packaging_fr',
}
export const commentAttributeMap: Record<string, string> = {
  en: 'comment',
  it: 'comment_it',
  es: 'comment_es',
  fr: 'comment_fr',
}
export const sizeAttributeMap: Record<string, string> = {
  en: 'size',
  it: 'size_it',
  es: 'size_es',
  fr: 'size_fr',
}

export const optionalGraphImage = [
  'https://ubqfructidorprod.blob.core.windows.net/prod-fructidor/website_static_images/open-graph.png',
  'https://ubqfructidorprod.blob.core.windows.net/prod-fructidor/website_static_images/fructidorName.webp',
  'https://ubqfructidorprod.blob.core.windows.net/prod-fructidor/website_static_images/no_image.svg',
]
