import { formatCountryData } from '@/app/types/constantDataType'
import { NewsItem, NewsErrors, NewsDetail } from '@/app/types/newsType'
import { formatSelectValue } from '@/components/ui/select/selectStyle'
import {
  addNews,
  getNewsDetail,
  getNewsList,
  updateNews,
} from '@/services/my_fructidor/edit/news/newsService'
import { createSlice } from '@reduxjs/toolkit'

export interface GetNewsList {
  news: NewsDetail[]
  count: number
}
interface News {
  newsForAll: GetNewsList
  loading: boolean
  newsLoading: boolean
  error: string
  isNewsAdded: boolean
  isNewsUpdated: boolean
  newsDetails: NewsItem
  newsError: NewsErrors
  isDetailsLoading: boolean
  isSubmited: boolean
}
const initialState: News = {
  newsForAll: {
    news: [],
    count: 0,
  },
  loading: false,
  newsLoading: false,
  error: '',
  isNewsAdded: false,
  isNewsUpdated: false,
  isDetailsLoading: false,
  isSubmited: false,
  newsDetails: {
    id: '',
    images: [],
    originalImageArray: [],
    title: '',
    caption: '',
    description: '',
    company_id: '',
    country: { id: -1, value: '', label: '' },
    language: { id: -1, value: '', label: '' },
    criteria: { id: -1, value: '', label: '' },
    origin: { id: '', name: '' },
    continent: '',
    created_at: '',
    updated_at: '',
    published_at: '',
    is_highlighted: false,
    is_sponsored: false,
    company: {
      name: '',
      sector: '',
      friendly_url: '',
    },
    signature: '',
  },
  newsError: {
    images: '',
    title: '',
    caption: '',
    description: '',
    criteria_id: '',
    country_id: '',
    language_id: '',
    message: '',
  },
}

export const newsSlice = createSlice({
  name: 'newsSlice',
  initialState,
  reducers: {
    setNews: (state, action) => {
      state.newsForAll = action.payload
    },
    setIsNewsAdded: (state) => {
      state.isNewsAdded = false
    },
    setIsNewsUpdated: (state) => {
      state.isNewsUpdated = false
    },
    setNewsDetails: (state, action) => {
      state.newsDetails = action.payload
    },
    setNewsDescription: (state, action) => {
      state.newsDetails.description = action.payload
    },
    setNewsAbstract: (state, action) => {
      state.newsDetails.caption = action.payload
    },
    setNewsErrors: (state, action) => {
      state.newsError = action.payload
    },
    setDefaultNewsData: (state) => {
      state.newsDetails = initialState.newsDetails
      state.isNewsAdded = false
      state.isNewsUpdated = false
      state.isDetailsLoading = false
      state.newsError = initialState.newsError
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNewsList.pending, (state) => {
      state.loading = true
      state.newsLoading = true
    })
    builder.addCase(getNewsList.fulfilled, (state: any, action) => {
      state.loading = false
      state.newsLoading = false
      state.isNewsUpdated = false
      state.isNewsAdded = false
      state.newsForAll = action.payload
    })
    builder.addCase(getNewsList.rejected, (state, action: any) => {
      state.loading = false
      state.newsLoading = false
      state.error = action.payload
    })
    builder.addCase(getNewsDetail.pending, (state) => {
      state.isDetailsLoading = true
      state.newsDetails = initialState.newsDetails
      state.newsError = initialState.newsError
    })
    builder.addCase(getNewsDetail.fulfilled, (state: any, action: any) => {
      state.isDetailsLoading = false
      state.newsDetails = action.payload?.news || {}
      state.newsError.message = action.payload?.message || ''
      state.newsError.title = action.payload?.title || ''
      state.newsDetails.images = action.payload?.news?.images || []
      state.newsDetails.originalImageArray = action.payload?.news?.images || []
      state.newsDetails.language = formatSelectValue(
        action.payload?.news?.language
      )
      state.newsDetails.country = formatCountryData({
        ...action.payload?.news?.origin,
      })
      state.newsDetails.criteria = formatSelectValue(
        action.payload?.news?.criteria
      )
    })
    builder.addCase(getNewsDetail.rejected, (state) => {
      state.isDetailsLoading = false
      state.newsDetails = initialState.newsDetails
    })
    builder.addCase(addNews.pending, (state) => {
      state.isDetailsLoading = true
    })
    builder.addCase(addNews.fulfilled, (state: any) => {
      state.isDetailsLoading = false
      state.isNewsAdded = true
    })
    builder.addCase(addNews.rejected, (state, action: any) => {
      state.isDetailsLoading = false
      state.error = action.payload
    })
    builder.addCase(updateNews.pending, (state) => {
      state.isDetailsLoading = true
    })
    builder.addCase(updateNews.fulfilled, (state: any) => {
      state.isDetailsLoading = false
      state.isNewsUpdated = true
    })
    builder.addCase(updateNews.rejected, (state, action: any) => {
      state.isDetailsLoading = false
      state.error = action.payload
    })
  },
})

export const {
  setNews,
  setIsNewsAdded,
  setIsNewsUpdated,
  setNewsDetails,
  setDefaultNewsData,
  setNewsErrors,
  setNewsDescription,
  setNewsAbstract,
} = newsSlice.actions
export default newsSlice.reducer
